import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { ImageBASE_URL } from "../ApiContext/Config";



import MyLink from "./MyLink";
import MyLink2 from "./MyLink2";
import { useNavigate } from "react-router-dom";
import "../Contact.css";

function Header({ BusinessSetting, Page }) {

  const navigate = useNavigate();
  const handleLink2 = (slug, pageId, id, title) => {
    const state = {
      pageId: pageId,
      id: id,
      title: title,
      slug: slug,
    };
    navigate(`/${slug}`, { state });
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuOpen2, setIsMenuOpen2] = useState(false);
  const [isMenuOpen3, setIsMenuOpen3] = useState(false);
  const [isMenuOpen4, setIsMenuOpen4] = useState(false);
  const [isMenuOpen5, setIsMenuOpen5] = useState(false);
  const [isMenuOpen6, setIsMenuOpen6] = useState(false);

  const menuRef = useRef(null);

  const toggleMenu = () => {

    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  const toggleMenu2 = () => {

    setIsMenuOpen2(!isMenuOpen2);
    setIsMenuOpen(true);
  };

  const toggleMenu3 = () => {

    setIsMenuOpen3(!isMenuOpen3);
    setIsMenuOpen(true);
  };
  const toggleMenu4 = () => {

    setIsMenuOpen4(!isMenuOpen4);
    setIsMenuOpen(true);
  };

  const toggleMenu5 = () => {

    setIsMenuOpen5(!isMenuOpen5);
    setIsMenuOpen(true);
  };

  const toggleMenu6 = () => {

    setIsMenuOpen6(!isMenuOpen6);
    setIsMenuOpen(true);
  };
  const handleCardClick = (slug) => {

    navigate(`/gallery/${slug}`);
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);



  const phoneNumbers =
    BusinessSetting && BusinessSetting.data
      ? [BusinessSetting.data.phone_no1, BusinessSetting.data.phone_no2]
      : [];



  const fullImageHeader =
    BusinessSetting && BusinessSetting.data
      ? `${ImageBASE_URL}${BusinessSetting.data.header_logo}`
      : "";
  const fullImage1 =
    BusinessSetting && BusinessSetting.data
      ? `${ImageBASE_URL}${BusinessSetting.data.image1}`
      : "";
  const fullImage2 =
    BusinessSetting && BusinessSetting.data
      ? `${ImageBASE_URL}${BusinessSetting.data.image2}`
      : "";


  return (
    <div
      data-elementor-type="header"
      data-elementor-id="8"
      class="elementor elementor-8 elementor-location-header"
    >
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-b121cc3 elementor-section-full_width lightHeader elementor-hidden-tablet elementor-hidden-mobile elementor-section-height-default elementor-section-height-default"
        data-id="b121cc3"
        data-element_type="section"
        data-settings='{"sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a5d86b4"
            data-id="a5d86b4"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-522fb6e elementor-widget elementor-widget-image"
                data-id="522fb6e"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <Link to="/">
                    <img
                      width={800}
                      height={80}
                      alt="pgdm colleges in up"
                      src={fullImageHeader}
                      sizes="(max-width: 1000px) 100vw, 1000px"
                      className="attachment-full size-full"
                    />
                    <noscript>
                      <img
                        width="600"
                        height="80"
                        src={fullImageHeader}
                        className="attachment-full size-full"
                        alt="pgdm colleges in up"
                        sizes="(max-width: 1000px) 100vw, 1000px"
                      />
                    </noscript>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5d05c29"
            data-id="5d05c29"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-a59f6f5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="a59f6f5"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d596768"
                    data-id="d596768"
                    data-element_type="column"
                  >
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-d56e511 elementor-nav-menu__align-right color-black elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                        data-id="d56e511"
                        data-element_type="widget"
                        data-settings='{"layout":"horizontal","submenu_icon":{"value":"&lt;i class=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"},"toggle":"burger"}'
                        data-widget_type="nav-menu.default"
                      >
                        <div class="elementor-widget-container">
                          <nav
                            migration_allowed="1"
                            migrated="0"
                            role="navigation"
                            class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none"
                          >
                            <ul
                              id="menu-1-d56e511"
                              class="elementor-nav-menu"
                            >
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2913">
                                <MyLink
                                  slug="immersive-experience"
                                  pageId="14"
                                  id="40"
                                  className="elementor-item"
                                >
                                  Immersive experience
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3357">
                                <MyLink
                                  slug="business-insights"
                                  pageId="14"
                                  id="41"
                                  className="elementor-item"
                                >
                                  Business Insights
                                </MyLink>
                              </li>
                              <li
                                onClick={() =>
                                  handleLink2(
                                    `alumni`,
                                    `555`,
                                    "1",
                                    `Alumni`
                                  )
                                }
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4982"
                              >
                                <Link to="#" className="elementor-item">
                                  Alumni Form
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4983">
                                <Link to="/" className="elementor-item">
                                  Student Login
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3332">
                                <Link
                                  to="/contact-us"
                                  className="elementor-item"
                                >
                                  Contact us
                                </Link>
                              </li>
                            </ul>
                          </nav>
                          <div
                            class="elementor-menu-toggle"
                            role="button"
                            tabindex="0"
                            aria-label="Menu Toggle"
                            aria-expanded="false"
                          >
                            <i
                              aria-hidden="true"
                              role="presentation"
                              class="elementor-menu-toggle__icon--open eicon-menu-bar"
                            ></i>
                            <i
                              aria-hidden="true"
                              role="presentation"
                              class="elementor-menu-toggle__icon--close eicon-close"
                            ></i>{" "}
                            <span class="elementor-screen-only">Menu</span>
                          </div>
                          <nav
                            class="elementor-nav-menu--dropdown elementor-nav-menu__container"
                            role="navigation"
                            aria-hidden="true"
                          >
                            <ul
                              id="menu-2-d56e511"
                              class="elementor-nav-menu"
                            >
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2913">
                                <MyLink
                                  slug="immersive-experience"
                                  pageId="14"
                                  id="40"
                                  className="elementor-item"
                                >
                                  Immersive experience
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3357">
                                <MyLink
                                  slug="business-insights"
                                  pageId="14"
                                  id="41"
                                  className="elementor-item"
                                >
                                  Business Insights
                                </MyLink>
                              </li>
                              <li
                                onClick={() =>
                                  handleLink2(
                                    `alumni`,
                                    `555`,
                                    "1",
                                    `Alumni`
                                  )
                                }
                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4982"
                              >
                                <Link to="#" className="elementor-item">
                                  Alumni Form
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4983">
                                <Link to="/" className="elementor-item">
                                  Student Login
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3332">
                                <Link
                                  to="/contact-us"
                                  className="elementor-item"
                                >
                                  Contact Us
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-3271acc"
                    data-id="3271acc"
                    data-element_type="column"
                  >
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-dfac1f9 elementor-icon-list--layout-inline elementor-align-right elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="dfac1f9"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items elementor-inline-items">
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <span className="elementor-icon-list-text">
                                Social Connect
                              </span>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link
                                to={
                                  BusinessSetting && BusinessSetting.data
                                    ? BusinessSetting.data.facebook
                                    : "/"
                                }
                              >
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-facebook"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link to={BusinessSetting.data.twitter}>
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-twitter"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link to={BusinessSetting.data.youtube}>
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-youtube"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link to={BusinessSetting.data.linkedin}>
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-linkedin"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link to={BusinessSetting.data.instagram}>
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-instagram"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d73bf9f"
                    data-id="d73bf9f"
                    data-element_type="column"
                  >
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-61c0786 elementor-icon-list--layout-inline elementor-align-right elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="61c0786"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div class="elementor-widget-container">
                          <ul class="elementor-icon-list-items elementor-inline-items">
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <span className="elementor-icon-list-text">
                                Touchpoints
                              </span>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link to={`tel:${phoneNumbers[0]}`}>
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fas fa-phone-square-alt"
                                  />{" "}
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link
                                to={
                                  `mailto:` + BusinessSetting.data.email_id1
                                }
                              >
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fas fa-envelope-open"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                            <li className="elementor-icon-list-item elementor-inline-item">
                              <Link to={`https://wa.me/${phoneNumbers[0]}`}>
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fab fa-whatsapp"
                                  />
                                </span>
                                <span className="elementor-icon-list-text" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* <!-- 2nd --> */}

              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-cdc4b7e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="cdc4b7e"
                data-element_type="section"
              >
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c66b9b1"
                    data-id="c66b9b1"
                    data-element_type="column"
                  >
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-54f6928 elementor-widget elementor-widget-wp-widget-maxmegamenu"
                        data-id="54f6928"
                        data-element_type="widget"
                        data-widget_type="wp-widget-maxmegamenu.default"
                      >
                        <ul class="elementor-widget-container">
                          <li
                            id="mega-menu-wrap-main-menu"
                            class="mega-menu-wrap"
                          >
                            <div class={`mega-menu-toggle`}>
                              <div class="mega-toggle-blocks-left"></div>
                              <div class="mega-toggle-blocks-center"></div>
                              <div class="mega-toggle-blocks-right">
                                <div
                                  ref={menuRef}
                                  class="mega-toggle-block mega-menu-toggle-animated-block mega-toggle-block-0"
                                  id="mega-toggle-block-0"
                                >
                                  <button
                                    aria-label="Toggle Menu"
                                    class="mega-toggle-animated mega-toggle-animated-slider"
                                    type="button"
                                    aria-expanded="false"
                                  >
                                    <span class="mega-toggle-animated-box">
                                      <span class="mega-toggle-animated-inner"></span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <ul
                              id="mega-menu-main-menu"
                              class="mega-menu max-mega-menu mega-menu-horizontal mega-no-js"
                              data-event="hover"
                              data-effect="slide"
                              data-effect-speed="200"
                              data-effect-mobile="slide_left"
                              data-effect-speed-mobile="200"
                              data-mobile-force-width="body"
                              data-second-click="go"
                              data-document-click="collapse"
                              data-vertical-behaviour="standard"
                              data-breakpoint="768"
                              data-unbind="true"
                              data-mobile-state="collapse_all"
                              data-hover-intent-timeout="300"
                              data-hover-intent-interval="100"
                            >
                              {/* 1 tab */}
                              <li
                                class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-16 `}
                                id="mega-menu-item-16"
                              >
                                <a
                                  class="mega-menu-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabindex="0"
                                >
                                  {Page && Page.data
                                    ? Page.data.page[7].name
                                    : ""}
                                  <span
                                    class="mega-indicator"
                                    onClick={toggleMenu2}
                                  ></span>
                                </a>

                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-row"
                                    id="mega-menu-16-0"
                                  >
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-16-0-0"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2129"
                                            id="mega-menu-item-2129"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="far fa-building" />
                                              All About GBAMS A Tribute
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2130 left-icon"
                                                id="mega-menu-item-2130"
                                              >
                                                <MyLink
                                                  slug="our-academy"
                                                  pageId="8"
                                                  id="2"
                                                  className="mega-menu-link"
                                                >
                                                  Vision, Mission &
                                                  Objectives
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2625"
                                            id="mega-menu-item-2625"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="far fa-building" />
                                              Our Legacy
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2142 left-icon"
                                                id="mega-menu-item-2142"
                                              >
                                                <MyLink
                                                  slug="binani-group"
                                                  pageId="8"
                                                  id="4"
                                                  className="mega-menu-link"
                                                >
                                                  Binani Group
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2133"
                                            id="mega-menu-item-2133"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-users" />
                                              &nbsp;&nbsp;GBAMS Inspiration
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2134 left-icon"
                                                id="mega-menu-item-2134"
                                              >
                                                <MyLink
                                                  slug="our-inspiration"
                                                  pageId="8"
                                                  id="6"
                                                  className="mega-menu-link"
                                                >
                                                  Our Inspiration
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-16-0-1"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2136"
                                            id="mega-menu-item-2136"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-users mr-3" />
                                              &nbsp;&nbsp;Director’s
                                              Messages
                                              <span className="mega-indicator" />{" "}
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2141 left-icon"
                                                id="mega-menu-item-2141"
                                              >
                                                <MyLink
                                                  slug="directors-messages"
                                                  pageId="8"
                                                  id="3"
                                                  className="mega-menu-link"
                                                >
                                                  From director’s desk
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>

                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2156"
                                            id="mega-menu-item-2156"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="far fa-building" />
                                              Infra Structure
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2627 left-icon"
                                                id="mega-menu-item-2627"
                                              >
                                                <MyLink
                                                  slug="library"
                                                  pageId="8"
                                                  id="7"
                                                  className="mega-menu-link"
                                                >
                                                  Library
                                                </MyLink>
                                              </li>
                                            </ul>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2627 left-icon"
                                                id="mega-menu-item-2627"
                                              >
                                                <MyLink
                                                  slug="facilities"
                                                  pageId="8"
                                                  id="8"
                                                  className="mega-menu-link"
                                                >
                                                  Facilities
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-16-0-2"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2137"
                                            id="mega-menu-item-2137"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-users" />
                                              &nbsp;&nbsp;Other Important
                                              Links
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2145 left-icon"
                                                id="mega-menu-item-2145"
                                              >
                                                <MyLink
                                                  slug="mandatory-disclosure"
                                                  pageId="8"
                                                  id="9"
                                                  className="mega-menu-link"
                                                >
                                                  Mandatory Disclosure
                                                </MyLink>
                                              </li>
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2629 left-icon"
                                                id="mega-menu-item-2629"
                                              >


                                                <MyLink
                                                  slug="anti-ragging"
                                                  pageId="8"
                                                  id="10"
                                                  className="mega-menu-link"
                                                >
                                                  Anti Ragging
                                                </MyLink>
                                              </li>
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2629 left-icon"
                                                id="mega-menu-item-2629"
                                              >
                                                <MyLink
                                                  slug="aicte-approval-"
                                                  pageId="8"
                                                  id="11"
                                                  className="mega-menu-link"
                                                >
                                                  AICTE Approval 
                                                </MyLink>
                                              </li>

                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2629 left-icon"
                                                id="mega-menu-item-2629"
                                              >


                                                <MyLink
                                                  slug="industry-collaborations"
                                                  pageId="8"
                                                  id="12"
                                                  className="mega-menu-link"
                                                >
                                                  Industry collaborations
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              {/* 2nd tab */}

                              <li
                                class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-1083 `}
                                id="mega-menu-item-1083"
                              >
                                <a
                                  class="mega-menu-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabindex="0"
                                  onClick={toggleMenu3}
                                >
                                  {Page && Page.data
                                    ? Page.data.page[8].name
                                    : ""}
                                  <span class="mega-indicator"></span>
                                </a>

                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-row"
                                    id="mega-menu-19-0"
                                  >
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-19-0-0"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-widget widget_media_image mega-menu-item-media_image-3"
                                            id="mega-menu-item-media_image-3"
                                          >
                                            {fullImage1 && (
                                              <img
                                                width={300}
                                                height={200}
                                                alt="pgdm colleges in up"
                                                style={{
                                                  maxWidth: "100%",
                                                  height: "auto",
                                                }}
                                                decoding="async"
                                                src={fullImage1}
                                                className="image wp-image-929 attachment-medium size-medium "
                                              />
                                            )}
                                          </li>
                                        </ul>
                                      </li>

                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-1083-0-1"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-1931"
                                            id="mega-menu-item-1931"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-user" />
                                              Reputation
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1924 left-icon"
                                                id="mega-menu-item-1924"
                                              >
                                                <MyLink
                                                  slug="mbabba-programme"
                                                  pageId="9"
                                                  id="13"
                                                  className="mega-menu-link"
                                                >
                                                  MBA/BBA PROGRAM
                                                </MyLink>
                                              </li>

                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1924 left-icon"
                                                id="mega-menu-item-1924"
                                              >
                                                <MyLink
                                                  slug="mbabba-course-structure"
                                                  pageId="9"
                                                  id="14"
                                                  className="mega-menu-link"
                                                >
                                                  MBA/BBA COURSE STRUCTURE
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>

                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-1931"
                                            id="mega-menu-item-1931"
                                          >
                                            <MyLink
                                              className="mega-menu-link"
                                              slug="admission-process"
                                              pageId="9"
                                              id="5"
                                            >
                                              <i className="fas fa-user" />
                                              ADMISSION PROCESS
                                              <span className="mega-indicator" />
                                            </MyLink>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1924 left-icon"
                                                id="mega-menu-item-1924"
                                              >
                                                <MyLink
                                                  slug="admission-process"
                                                  pageId="9"
                                                  id="5"
                                                  className="mega-menu-link"
                                                >
                                                  Admission Process
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2124"
                                            id="mega-menu-item-2124"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-graduation-cap" />
                                              &nbsp;&nbsp;SCHOLARSHIP
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1926 left-icon"
                                                id="mega-menu-item-1926"
                                              >
                                                <Link
                                                  to="http://samajkalyan.up.gov.in/"
                                                  target="_blank"
                                                  className="mega-menu-link"
                                                >
                                                  Scholarship Policy
                                                </Link>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2126"
                                            id="mega-menu-item-2126"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="far fa-copy" />
                                              STUDENT REGISTRATION &
                                              ADMISSION{" "}
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1928 left-icon"
                                                id="mega-menu-item-1928"
                                              >
                                                <MyLink
                                                  slug="online-form"
                                                  pageId="9"
                                                  id="16"
                                                  className="mega-menu-link"
                                                >
                                                  Online Form
                                                </MyLink>
                                              </li>
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1928 left-icon"
                                                id="mega-menu-item-1928"
                                              >
                                                <MyLink
                                                  slug="student-login"
                                                  pageId="9"
                                                  id="17"
                                                  className="mega-menu-link"
                                                >
                                                  Student Login
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>

                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-1083-0-2"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2123"
                                            id="mega-menu-item-2123"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="far fa-money-bill-alt" />
                                              &nbsp;&nbsp;ELIGIBILITY & FEES
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1925 left-icon"
                                                id="mega-menu-item-1925"
                                              >
                                                <MyLink
                                                  slug="eligibility-fees-details"
                                                  pageId="9"
                                                  id="18"
                                                  className="mega-menu-link"
                                                >
                                                  Eligibility & Fees details
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2125"
                                            id="mega-menu-item-2125"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fab fa-angular" />
                                              &nbsp;&nbsp;PROSPECTIVE
                                              ADVANTAGES
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1927 left-icon"
                                                id="mega-menu-item-1927"
                                              >
                                                <MyLink
                                                  slug="gbams-edge-over-others"
                                                  pageId="9"
                                                  id="19"
                                                  className="mega-menu-link"
                                                >
                                                  GBAMS Edge over others
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2127"
                                            id="mega-menu-item-2127"
                                          >
                                            <MyLink
                                              slug="college-brochure"
                                              pageId="9"
                                              id="20"
                                              className="mega-menu-link"
                                            >
                                              <i className="far fa-address-book" />
                                              COLLEGE BROCHURE
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              {/* 3rd tab */}

                              <li
                                class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-1083 `}
                                id="mega-menu-item-1083"
                              >
                                <a
                                  class="mega-menu-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabindex="0"
                                  onClick={toggleMenu4}
                                >
                                  {Page && Page.data
                                    ? Page.data.page[9].name
                                    : ""}
                                  <span class="mega-indicator"></span>
                                </a>

                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-row"
                                    id="mega-menu-19-0"
                                  >
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-19-0-0"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3038"
                                            id="mega-menu-item-3038"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-graduation-cap" />
                                              &nbsp;&nbsp;GBAMS Placement
                                              Process
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-3037 left-icon"
                                                id="mega-menu-item-3037"
                                              >
                                                <MyLink
                                                  slug="placement-process-calendar"
                                                  pageId="10"
                                                  id="21"
                                                  className="mega-menu-link"
                                                >
                                                  Placement Process &amp;
                                                  Calendar
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3040"
                                            id="mega-menu-item-3040"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-graduation-cap" />
                                              &nbsp;&nbsp;GBAMS Reports{" "}
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-5923 left-icon"
                                                id="mega-menu-item-5923"
                                              >
                                                <MyLink
                                                  slug="placement-report"
                                                  pageId="10"
                                                  id="22"
                                                  className="mega-menu-link"
                                                >
                                                  Placement Report
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3042"
                                            id="mega-menu-item-3042"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-graduation-cap" />
                                              &nbsp;&nbsp;GBAMS Recruiters
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2114 left-icon"
                                                id="mega-menu-item-2114"
                                              >
                                                <MyLink
                                                  slug="our-recruiters"
                                                  pageId="10"
                                                  id="23"
                                                  className="mega-menu-link"
                                                >
                                                  Our Recruiters
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-3044"
                                            id="mega-menu-item-3044"
                                          >
                                            <MyLink
                                              slug="business-insights"
                                              pageId="10"
                                              id="24"
                                              className="mega-menu-link"
                                            >
                                              <i className="far fa-building" />
                                              Business Insights
                                            </MyLink>
                                          </li>
                                        </ul>
                                      </li>
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-19-0-1"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3039"
                                            id="mega-menu-item-3039"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-user" />
                                              GBAMS Student's
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-1094 left-icon"
                                                id="mega-menu-item-1094"
                                              >
                                                <MyLink
                                                  slug="students-profile"
                                                  pageId="10"
                                                  id="25"
                                                  className="mega-menu-link"
                                                >
                                                  Student's Profile
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3041"
                                            id="mega-menu-item-3041"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-graduation-cap" />
                                              &nbsp;&nbsp;GBAMS Internship
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2767 left-icon"
                                                id="mega-menu-item-2767"
                                              >
                                                <MyLink
                                                  slug="summer-internship-details"
                                                  pageId="10"
                                                  id="26"
                                                  className="mega-menu-link"
                                                >
                                                  Summer Internship Details
                                                </MyLink>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3043"
                                            id="mega-menu-item-3043"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="far fa-building" />
                                              Industry connect
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2787 left-icon"
                                                id="mega-menu-item-2787"
                                              >
                                                <MyLink
                                                  slug="learning-by-practice"
                                                  pageId="10"
                                                  id="27"
                                                  className="mega-menu-link"
                                                >
                                                  Learning By Practice
                                                </MyLink>
                                              </li>
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2787 left-icon"
                                                id="mega-menu-item-2787"
                                              >
                                                <Link
                                                  className="mega-menu-link"
                                                  to="/industry-speak"
                                                >
                                                 
                                                
                                                  Industry Speaks
                                                  <span className="mega-indicator" />
                                                </Link>


                                              </li>
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2787 left-icon"
                                                id="mega-menu-item-2787"
                                              >
                                                <Link
                                                  to="/alumni-list"
                                                  className="mega-menu-link"
                                                >
                                                  Alumni
                                                </Link>
                                              </li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-19-0-2"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-widget widget_media_image mega-menu-item-media_image-5"
                                            id="mega-menu-item-media_image-5"
                                          >
                                            {fullImage2 && (
                                              <img
                                                width={800}
                                                height={534}
                                                style={{
                                                  maxWidth: "100%",
                                                  height: "auto",
                                                }}
                                                decoding="async"
                                                src={fullImage2}
                                                className="image wp-image-1898 attachment-large size-large "
                                              />
                                            )}
                                            <noscript>
                                              <img
                                                width="800"
                                                height="534"
                                                alt="dynamic image"
                                                style={{
                                                  maxWidth: "100%",
                                                  height: "auto",
                                                }}
                                                src={fullImage2}
                                                className="image wp-image-1898 attachment-large size-large"
                                              />
                                            </noscript>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              {/* 4 tab */}
                              <li
                                class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-align-bottom-left mega-menu-flyout mega-menu-item-18 `}
                                id="mega-menu-item-18"
                              >
                                <a
                                  class="mega-menu-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabindex="0"
                                  onClick={toggleMenu5}
                                >
                                  {Page && Page.data
                                    ? Page.data.page[10].name
                                    : ""}
                                  <span class="mega-indicator"></span>
                                </a>
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1930"
                                    id="mega-menu-item-1930"
                                  >
                                    <MyLink2 className="mega-menu-link">
                                      Lectures & presentation
                                      video’s/photo’s of faculty members
                                    </MyLink2>
                                  </li>{" "}
                                  <li
                                    className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1930"
                                    id="mega-menu-item-1930"
                                  >
                                    <Link
                                      className="mega-menu-link"
                                      to={
                                        `mailto:` +
                                        BusinessSetting.data.email_id1
                                      }
                                    >
                                      Career-
                                      {BusinessSetting &&
                                        BusinessSetting.data
                                        ? BusinessSetting.data.email_id1
                                        : ""}
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                              {/* 5 tab */}

                              <li
                                class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-19 ${`${isMenuOpen6 ? "mega-toggle-on" : ""
                                  }`}`}
                                id="mega-menu-item-19"
                              >
                                <a
                                  class="mega-menu-link"
                                  href="#"
                                  aria-haspopup="true"
                                  aria-expanded={`${isMenuOpen6 ? "true" : ""
                                    }`}
                                  tabindex="0"
                                  onClick={toggleMenu6}
                                >
                                  {" "}
                                  {Page && Page.data
                                    ? Page.data.page[11].name
                                    : ""}
                                  <span class="mega-indicator"></span>
                                </a>

                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-row"
                                    id="mega-menu-20-0"
                                  >
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-20-0-0"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2147"
                                            id="mega-menu-item-2147"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-camera" />
                                              GBAMS Photo's
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2107 left-icon"
                                                id="mega-menu-item-2107"
                                              >

                                                <Link
                                                  className="mega-menu-link"
                                                  to={`/gallery/photo-gallery-category`}
                                                  onClick={() => handleCardClick("photo-gallery-category")}
                                                >
                                                 
                                                  <span className="elementor-icon-list-text">Photo Gallery</span>
                                                </Link>

                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2149"
                                            id="mega-menu-item-2149"
                                            onClick={() =>
                                              handleLink2(
                                                `${Page.data.page[12].slug}`,
                                                `${Page.data.page[12].id}`,
                                                "1",
                                                `${Page.data.page[12].name}`
                                              )
                                            }
                                          >
                                            <Link
                                              to={"#"}
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-calendar-alt" />
                                              Events Corner
                                              <span className="mega-indicator" />
                                            </Link>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2151"
                                            id="mega-menu-item-2151"
                                          >
                                            <MyLink
                                              slug="near-by-pious-spots-scenic-beauty"
                                              pageId="12"
                                              id="33"
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-map-marked-alt" />
                                              &nbsp;&nbsp;Near By Pious
                                              spots & scenic beauty
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-4049"
                                            id="mega-menu-item-4049"
                                          >
                                            <MyLink
                                              slug="learning-at-gbams"
                                              pageId="12"
                                              id="34"
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-check-circle" />
                                              Learning at GBAMS
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                        </ul>
                                      </li>
                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-20-0-1"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2148"
                                            id="mega-menu-item-2148"
                                          >
                                            <Link
                                              className="mega-menu-link"
                                              to="/"
                                            >
                                              <i className="fas fa-video" />
                                              &nbsp;&nbsp;GBAMS Video's
                                              <span className="mega-indicator" />
                                            </Link>
                                            <ul className="mega-sub-menu">
                                              <li
                                                className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2106 left-icon"
                                                id="mega-menu-item-2106"
                                              >
                                                <Link
                                                  className="mega-menu-link"
                                                  to={`/gallery/video-gallery-category`}
                                                  onClick={() => handleCardClick("video-gallery-category")}
                                                >
                                                 
                                                  <span className="elementor-icon-list-text">Video Gallery</span>
                                                </Link>
                                              </li>
                                            </ul>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2150"
                                            id="mega-menu-item-2150"
                                          >
                                            <MyLink
                                              slug="personality-enrichment-activities"
                                              pageId="12"
                                              id="36"
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-building" />
                                              Personality enrichment
                                              activities
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2152"
                                            id="mega-menu-item-2152"
                                          >
                                            <MyLink
                                              slug="social-entrepreneurial-initiatives"
                                              pageId="12"
                                              id="37"
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-building" />
                                              Social & entrepreneurial
                                              initiatives
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2684"
                                            id="mega-menu-item-2684"
                                          >
                                            <MyLink
                                              slug="pre-placement-training-skill-buildingimmersive-experiences"
                                              pageId="12"
                                              id="38"
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-graduation-cap" />
                                              &nbsp;&nbsp; Pre placement
                                              training & skill
                                              building(Immersive
                                              Experiences){" "}
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                        </ul>
                                      </li>

                                      <li
                                        className="mega-menu-column mega-menu-columns-4-of-12"
                                        id="mega-menu-20-0-1"
                                      >
                                        <ul className="mega-sub-menu">
                                          <li
                                            className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2148"
                                            id="mega-menu-item-2148"
                                          >
                                            <MyLink
                                              slug="incubation-club"
                                              pageId="12"
                                              id="39"
                                              className="mega-menu-link"
                                            >
                                              <i className="fas fa-video" />
                                              &nbsp;&nbsp;Incubation Club
                                              <span className="mega-indicator" />
                                            </MyLink>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              {/* 6tab */}
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* 3 */}
            </div>
          </div>
        </div>
      </section>

      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-3750e7f elementor-hidden-desktop elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="3750e7f"
        data-element_type="section"
        data-settings='{"background_background":"classic"}'
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97c600b"
            data-id="97c600b"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-6f36efd elementor-nav-menu__align-justify color-black elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu"
                data-id="6f36efd"
                data-element_type="widget"
                data-settings='{"layout":"horizontal","submenu_icon":{"value":"&lt;i class=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"}}'
                data-widget_type="nav-menu.default"
              >
                <div class="elementor-widget-container">
                  <nav
                    migration_allowed="1"
                    migrated="0"
                    role="navigation"
                    class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none"
                  >
                    <ul id="menu-1-6f36efd" class="elementor-nav-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2913">
                        <MyLink
                          slug="immersive-experience"
                          pageId="14"
                          id="40"
                          className="elementor-item"
                        >
                          Immersive experience
                        </MyLink>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3357">
                        <MyLink
                          slug="business-insights"
                          pageId="14"
                          id="41"
                          className="elementor-item"
                        >
                          Business Insights
                        </MyLink>
                      </li>
                      <li
                        onClick={() =>
                          handleLink2(`alumni`, `555`, "1", `Alumni`)
                        }
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4982"
                      >
                        <Link to="#" className="elementor-item">
                          Alumni Form
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4983">
                        <Link to="/" className="elementor-item">
                          Student Login
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3332">
                        <Link to="/contact-us" className="elementor-item">
                          contact us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                  <div
                    class="elementor-menu-toggle"
                    role="button"
                    tabindex="0"
                    aria-label="Menu Toggle"
                    aria-expanded="false"
                  >
                    <i
                      aria-hidden="true"
                      role="presentation"
                      class="elementor-menu-toggle__icon--open eicon-menu-bar"
                    ></i>
                    <i
                      aria-hidden="true"
                      role="presentation"
                      class="elementor-menu-toggle__icon--close eicon-close"
                    ></i>{" "}
                    <span class="elementor-screen-only">Menu</span>
                  </div>
                  <nav
                    class="elementor-nav-menu--dropdown elementor-nav-menu__container"
                    role="navigation"
                    aria-hidden="true"
                  >
                    <ul id="menu-2-6f36efd" class="elementor-nav-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2913">
                        <Link to="/" className="elementor-item">
                          Immersive experience
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3357">
                        <Link to="/" className="elementor-item">
                          Business Insights
                        </Link>
                      </li>
                      <li
                        onClick={() =>
                          handleLink2(`alumni`, `555`, "1", `Alumni`)
                        }
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4982"
                      >
                        <Link to="#" className="elementor-item">
                          Alumni Form
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-4983">
                        <Link to="/" className="elementor-item">
                          Student Login
                        </Link>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3332">
                        <Link to="/contact-us" className="elementor-item">
                          contact us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-120ef91 elementor-hidden-desktop mob-darkHeader elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="120ef91"
        data-element_type="section"
      >
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8af682c"
            data-id="8af682c"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-0c8c820 elementor-widget elementor-widget-image"
                data-id="0c8c820"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <Link to="/">
                    <img
                      width={800}
                      height={80}
                      alt="pgdm colleges in up"
                      src={fullImageHeader}
                      sizes="(max-width: 1000px) 100vw, 1000px"
                      className="attachment-full size-full"
                    />
                    <noscript>
                      <img
                        width="600"
                        height="80"
                        src={fullImageHeader}
                        className="attachment-full size-full"
                        alt="pgdm colleges in up"
                        sizes="(max-width: 1000px) 100vw, 1000px"
                      />
                    </noscript>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- mobile --> */}

          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0e9c2f1"
            data-id="0e9c2f1"
            data-element_type="column"
          >
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-b848e7d elementor-widget elementor-widget-wp-widget-maxmegamenu"
                data-id="b848e7d"
                data-element_type="widget"
                data-widget_type="wp-widget-maxmegamenu.default"
              >
                <ul class="elementor-widget-container">
                  <li id="mega-menu-wrap-main-menu" class="mega-menu-wrap">
                    <div
                      class={`mega-menu-toggle ${isMenuOpen ? "mega-menu-open" : ""
                        }`}
                    >
                      <div class="mega-toggle-blocks-left"></div>
                      <div class="mega-toggle-blocks-center"></div>
                      <div class="mega-toggle-blocks-right">
                        <div
                          ref={menuRef}
                          class="mega-toggle-block mega-menu-toggle-animated-block mega-toggle-block-0"
                          id="mega-toggle-block-0"
                        >
                          <button
                            aria-label="Toggle Menu 2"
                            class="mega-toggle-animated mega-toggle-animated-slider"
                            type="button"
                            aria-expanded={isMenuOpen ? "true" : "false"}
                            onClick={toggleMenu}
                          >
                            <span class="mega-toggle-animated-box">
                              <span class="mega-toggle-animated-inner"></span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul
                      id="mega-menu-main-menu"
                      class="mega-menu max-mega-menu mega-menu-horizontal mega-no-js"
                      data-event="hover"
                      data-effect="slide"
                      data-effect-speed="200"
                      data-effect-mobile="slide_left"
                      data-effect-speed-mobile="200"
                      data-mobile-force-width="body"
                      data-second-click="go"
                      data-document-click="collapse"
                      data-vertical-behaviour="standard"
                      data-breakpoint="768"
                      data-unbind="true"
                      data-mobile-state="collapse_all"
                      data-hover-intent-timeout="300"
                      data-hover-intent-interval="100"
                    >
                      <li
                        class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-16 ${`${isMenuOpen2 ? "mega-toggle-on" : ""
                          }`}`}
                        id="mega-menu-item-16"
                      >
                        <a
                          class="mega-menu-link"
                          href="#"
                          aria-haspopup="true"
                          aria-expanded={`${isMenuOpen2 ? "true" : "false"
                            }`}
                          tabindex="0"
                          onClick={toggleMenu2}
                        >
                          {Page && Page.data ? Page.data.page[7].name : ""}
                          <span class="mega-indicator"></span>
                        </a>

                        <ul className="mega-sub-menu">
                          <li className="mega-menu-row" id="mega-menu-16-0">
                            <ul className="mega-sub-menu">
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-16-0-0"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2129"
                                    id="mega-menu-item-2129"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="far fa-building" />
                                      All About GBAMS A Tribute
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2130 left-icon"
                                        id="mega-menu-item-2130"
                                      >
                                        <MyLink
                                          slug="our-academy"
                                          pageId="8"
                                          id="2"
                                          className="mega-menu-link"
                                        >
                                          Vision, Mission & Objectives
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2625"
                                    id="mega-menu-item-2625"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="far fa-building" />
                                      Our Legacy
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2142 left-icon"
                                        id="mega-menu-item-2142"
                                      >
                                        <MyLink
                                          slug="binani-group"
                                          pageId="8"
                                          id="4"
                                          className="mega-menu-link"
                                        >
                                          Binani Group
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2133"
                                    id="mega-menu-item-2133"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-users" />
                                      &nbsp;&nbsp;GBAMS Inspiration
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2134 left-icon"
                                        id="mega-menu-item-2134"
                                      >
                                        <MyLink
                                          slug="our-inspiration"
                                          pageId="8"
                                          id="6"
                                          className="mega-menu-link"
                                        >
                                          Our Inspiration
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-16-0-1"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2136"
                                    id="mega-menu-item-2136"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-users mr-3" />
                                      &nbsp;&nbsp;Director’s Messages
                                      <span className="mega-indicator" />{" "}
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2141 left-icon"
                                        id="mega-menu-item-2141"
                                      >
                                        <MyLink
                                          slug="directors-messages"
                                          pageId="8"
                                          id="3"
                                          className="mega-menu-link"
                                        >
                                          From director’s desk
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>

                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2156"
                                    id="mega-menu-item-2156"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="far fa-building" />
                                      Infra Structure
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2627 left-icon"
                                        id="mega-menu-item-2627"
                                      >
                                        <MyLink
                                          slug="library"
                                          pageId="8"
                                          id="7"
                                          className="mega-menu-link"
                                        >
                                          Library
                                        </MyLink>
                                      </li>
                                    </ul>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2627 left-icon"
                                        id="mega-menu-item-2627"
                                      >
                                        <MyLink
                                          slug="facilities"
                                          pageId="8"
                                          id="8"
                                          className="mega-menu-link"
                                        >
                                          Facilities
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-16-0-2"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2137"
                                    id="mega-menu-item-2137"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-users" />
                                      &nbsp;&nbsp;Other Important Links
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2145 left-icon"
                                        id="mega-menu-item-2145"
                                      >
                                        <MyLink
                                          slug="mandatory-disclosure"
                                          pageId="8"
                                          id="9"
                                          className="mega-menu-link"
                                        >
                                          Mandatory Disclosure
                                        </MyLink>
                                      </li>
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2629 left-icon"
                                        id="mega-menu-item-2629"
                                      >
                                        <Link
                                          slug="anti-ragging"
                                          pageId="8"
                                          id="10"
                                          className="mega-menu-link"
                                        >
                                          Anti Ragging
                                        </Link>
                                      </li>

                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2629 left-icon"
                                        id="mega-menu-item-2629"
                                      >
                                        <MyLink
                                          slug="aicte-approval-"
                                          pageId="8"
                                          id="11"
                                          className="mega-menu-link"
                                        >
                                          AICTE Approval 
                                        </MyLink>
                                      </li>

                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-2629 left-icon"
                                        id="mega-menu-item-2629"
                                      >
                                        <MyLink
                                          slug="industry-collaborations"
                                          pageId="8"
                                          id="12"
                                          className="mega-menu-link"
                                        >
                                          Industry collaborations
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      {/* 3tab in mobile */}
                      <li
                        class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-1083 ${`${isMenuOpen3 ? "mega-toggle-on" : ""
                          }`}`}
                        id="mega-menu-item-1083"
                      >
                        <a
                          class="mega-menu-link"
                          href="#"
                          aria-haspopup="true"
                          aria-expanded={`${isMenuOpen3 ? "true" : ""}`}
                          tabindex="0"
                          onClick={toggleMenu3}
                        >
                          {Page && Page.data ? Page.data.page[8].name : ""}
                          <span class="mega-indicator"></span>
                        </a>

                        <ul className="mega-sub-menu">
                          <li
                            className="mega-menu-row"
                            id="mega-menu-1083-0"
                          >
                            <ul className="mega-sub-menu">
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-1083-0-0"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-widget widget_media_image mega-menu-item-media_image-3"
                                    id="mega-menu-item-media_image-3"
                                  >
                                    {fullImage1 && (
                                      <img
                                        width={300}
                                        height={200}
                                        alt="pgdm colleges in up"
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                        decoding="async"
                                        src={fullImage1}
                                        className="image wp-image-929 attachment-medium size-medium "
                                      />
                                    )}
                                  </li>
                                </ul>
                              </li>

                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-1083-0-1"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-1931"
                                    id="mega-menu-item-1931"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-user" />
                                      Reputation
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1924 left-icon"
                                        id="mega-menu-item-1924"
                                      >
                                        <MyLink
                                          slug="mbabba-programme"
                                          pageId="9"
                                          id="13"
                                          className="mega-menu-link"
                                        >
                                          MBA/BBA PROGRAM
                                        </MyLink>
                                      </li>

                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1924 left-icon"
                                        id="mega-menu-item-1924"
                                      >
                                        <MyLink
                                          slug="mbabba-course-structure"
                                          pageId="9"
                                          id="14"
                                          className="mega-menu-link"
                                        >
                                          MBA/BBA COURSE STRUCTURE
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>

                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-1931"
                                    id="mega-menu-item-1931"
                                  >
                                    <Link className="mega-menu-link" to="#">
                                      <i className="fas fa-user" />
                                      ADMISSION PROCESS
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1924 left-icon"
                                        id="mega-menu-item-1924"
                                      >
                                        <MyLink
                                          slug="admission-process"
                                          pageId="9"
                                          id="5"
                                          className="mega-menu-link"
                                        >
                                          Admission Process
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2124"
                                    id="mega-menu-item-2124"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-graduation-cap" />
                                      &nbsp;&nbsp;SCHOLARSHIP
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1926 left-icon"
                                        id="mega-menu-item-1926"
                                      >
                                        <Link
                                          to="http://samajkalyan.up.gov.in/"
                                          target="_blank"
                                          className="mega-menu-link"
                                        >
                                          Scholarship Policy
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2126"
                                    id="mega-menu-item-2126"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="far fa-copy" />
                                      STUDENT REGISTRATION & ADMISSION{" "}
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1928 left-icon"
                                        id="mega-menu-item-1928"
                                      >
                                        <MyLink
                                          slug="online-form"
                                          pageId="9"
                                          id="16"
                                          className="mega-menu-link"
                                        >
                                          Online Form
                                        </MyLink>
                                      </li>
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1928 left-icon"
                                        id="mega-menu-item-1928"
                                      >
                                        <MyLink
                                          slug="student-form"
                                          pageId="10"
                                          id="16"
                                          className="mega-menu-link"
                                        >
                                          Student Login
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-1083-0-2"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2123"
                                    id="mega-menu-item-2123"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="far fa-money-bill-alt" />
                                      &nbsp;&nbsp;ELIGIBILITY & FEES
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1925 left-icon"
                                        id="mega-menu-item-1925"
                                      >
                                        <Link
                                          slug="eligibility-fees-details"
                                          pageId="9"
                                          id="18"
                                          className="mega-menu-link"
                                        >
                                          Eligibility & Fees details
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2125"
                                    id="mega-menu-item-2125"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fab fa-angular" />
                                      &nbsp;&nbsp;PROSPECTIVE ADVANTAGES
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1927 left-icon"
                                        id="mega-menu-item-1927"
                                      >
                                        <MyLink
                                          slug="gbams-edge-over-others"
                                          pageId="9"
                                          id="19"
                                          className="mega-menu-link"
                                        >
                                          GBAMS Edge over others
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2127"
                                    id="mega-menu-item-2127"
                                  >
                                    <MyLink
                                      slug="college-brochure"
                                      pageId="9"
                                      id="20"
                                      className="mega-menu-link"
                                    >
                                      <i className="far fa-address-book" />
                                      COLLEGE BROCHURE
                                      <span className="mega-indicator" />
                                    </MyLink>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      {/* 4tab in mobile */}

                      <li
                        class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-1083 ${`${isMenuOpen4 ? "mega-toggle-on" : ""
                          }`}`}
                        id="mega-menu-item-1083"
                      >
                        <a
                          class="mega-menu-link"
                          href="#"
                          aria-haspopup="true"
                          aria-expanded={`${isMenuOpen4 ? "true" : "false"
                            }`}
                          tabindex="0"
                          onClick={toggleMenu4}
                        >
                          {Page && Page.data ? Page.data.page[9].name : ""}
                          <span class="mega-indicator"></span>
                        </a>

                        <ul className="mega-sub-menu">
                          <li className="mega-menu-row" id="mega-menu-19-0">
                            <ul className="mega-sub-menu">
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-19-0-0"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3038"
                                    id="mega-menu-item-3038"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-graduation-cap" />
                                      &nbsp;&nbsp;GBAMS Placement Process
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-3037 left-icon"
                                        id="mega-menu-item-3037"
                                      >
                                        <MyLink
                                          slug="placement-process-calendar"
                                          pageId="10"
                                          id="21"
                                          className="mega-menu-link"
                                        >
                                          Placement Process &amp; Calendar
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3040"
                                    id="mega-menu-item-3040"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-graduation-cap" />
                                      &nbsp;&nbsp;GBAMS Reports{" "}
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-5923 left-icon"
                                        id="mega-menu-item-5923"
                                      >
                                        <MyLink
                                          slug="placement-report"
                                          pageId="10"
                                          id="22"
                                          className="mega-menu-link"
                                        >
                                          Placement Report
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3042"
                                    id="mega-menu-item-3042"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-graduation-cap" />
                                      &nbsp;&nbsp;GBAMS Recruiters
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2114 left-icon"
                                        id="mega-menu-item-2114"
                                      >
                                        <MyLink
                                          slug="our-recruiters"
                                          pageId="10"
                                          id="23"
                                          className="mega-menu-link"
                                        >
                                          Our Recruiters
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-3044"
                                    id="mega-menu-item-3044"
                                  >
                                    <MyLink
                                      slug="business-insights"
                                      pageId="10"
                                      id="24"
                                      className="mega-menu-link"
                                    >
                                      <i className="far fa-building" />
                                      Business Insights
                                    </MyLink>
                                  </li>
                                </ul>
                              </li>
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-19-0-1"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3039"
                                    id="mega-menu-item-3039"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-user" />
                                      GBAMS Student's
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-1094 left-icon"
                                        id="mega-menu-item-1094"
                                      >
                                        <MyLink
                                          slug="students-profile"
                                          pageId="10"
                                          id="25"
                                          className="mega-menu-link"
                                        >
                                          Student's Profile
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3041"
                                    id="mega-menu-item-3041"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-graduation-cap" />
                                      &nbsp;&nbsp;GBAMS Internship
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2767 left-icon"
                                        id="mega-menu-item-2767"
                                      >
                                        <MyLink
                                          slug="summer-internship-details"
                                          pageId="10"
                                          id="26"
                                          className="mega-menu-link"
                                        >
                                          Summer Internship Details
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-3043"
                                    id="mega-menu-item-3043"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="far fa-building" />
                                      Industry connect
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2787 left-icon"
                                        id="mega-menu-item-2787"
                                      >
                                        <MyLink
                                          slug="learning-by-practice"
                                          pageId="10"
                                          id="27"
                                          className="mega-menu-link"
                                        >
                                          Learning By Practice
                                        </MyLink>
                                      </li>
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2787 left-icon"
                                        id="mega-menu-item-2787"
                                      >
                                        <Link
                                          className="mega-menu-link"
                                          to="/industry-speak"
                                        >
                                         
                                         
                                          Industry Speaks
                                          <span className="mega-indicator" />
                                        </Link>
                                      </li>
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2787 left-icon"
                                        id="mega-menu-item-2787"
                                      >
                                        <Link to="/alumni">
                                          Alumni Registration Form
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-19-0-2"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-widget widget_media_image mega-menu-item-media_image-5"
                                    id="mega-menu-item-media_image-5"
                                  >
                                    {fullImage2 && (
                                      <img
                                        width={800}
                                        height={534}
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                        decoding="async"
                                        src={fullImage2}
                                        className="image wp-image-1898 attachment-large size-large "
                                      />
                                    )}
                                    <noscript>
                                      <img
                                        width="800"
                                        height="534"
                                        alt="dynamic image"
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                        src={fullImage2}
                                        className="image wp-image-1898 attachment-large size-large"
                                      />
                                    </noscript>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      {/* 4tab in mobile */}
                      <li
                        class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-align-bottom-left mega-menu-flyout mega-menu-item-18 ${`${isMenuOpen5 ? "mega-toggle-on" : ""
                          }`}`}
                        id="mega-menu-item-18"
                      >
                        <a
                          href="#"
                          class="mega-menu-link"
                          aria-haspopup="true"
                          aria-expanded={`${isMenuOpen5 ? "true" : "false"
                            }`}
                          tabindex="0"
                          onClick={toggleMenu5}
                        >
                          {" "}
                          {Page && Page.data ? Page.data.page[10].name : ""}
                          <span class="mega-indicator"></span>
                        </a>
                        <ul className="mega-sub-menu">
                          <li
                            className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1930"
                            id="mega-menu-item-1930"
                          >
                            <MyLink2 className="mega-menu-link">
                              Lectures & presentation video’s/photo’s of
                              faculty members
                            </MyLink2>
                          </li>{" "}
                          <li
                            className="mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-1930"
                            id="mega-menu-item-1930"
                          >
                            <Link
                              className="mega-menu-link"
                              to={
                                `mailto:` + BusinessSetting.data.email_id1
                              }
                            >
                              Career-
                              {BusinessSetting && BusinessSetting.data
                                ? BusinessSetting.data.email_id1
                                : ""}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li
                        class={`mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-megamenu mega-align-bottom-left mega-menu-grid mega-menu-item-19 ${`${isMenuOpen6 ? "mega-toggle-on" : ""
                          }`}`}
                        id="mega-menu-item-19"
                      >
                        <a
                          class="mega-menu-link"
                          href="#"
                          aria-haspopup="true"
                          aria-expanded={`${isMenuOpen6 ? "true" : ""}`}
                          tabindex="0"
                          onClick={toggleMenu6}
                        >
                          {Page && Page.data ? Page.data.page[11].name : ""}
                          <span class="mega-indicator"></span>
                        </a>

                        <ul className="mega-sub-menu">
                          <li className="mega-menu-row" id="mega-menu-20-0">
                            <ul className="mega-sub-menu">
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-20-0-0"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2147"
                                    id="mega-menu-item-2147"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-camera" />
                                      GBAMS Photo's
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2107 left-icon"
                                        id="mega-menu-item-2107"
                                      >
                                        <Link
                                          className="mega-menu-link"
                                          to={`/gallery/photo-gallery-category`}
                                          onClick={() => handleCardClick("photo-gallery-category")}
                                        >
                                         
                                          <span className="elementor-icon-list-text">Photo Gallery</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2149"
                                    id="mega-menu-item-2149"
                                    onClick={() =>
                                      handleLink2(
                                        `${Page.data.page[12].slug}`,
                                        `${Page.data.page[12].id}`,
                                        "1",
                                        `${Page.data.page[12].name}`
                                      )
                                    }
                                  >
                                    <Link
                                      to={"#"}
                                      className="mega-menu-link"
                                    >
                                      <i className="fas fa-calendar-alt" />
                                      Events Corner
                                      <span className="mega-indicator" />
                                    </Link>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2151"
                                    id="mega-menu-item-2151"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-map-marked-alt" />
                                      &nbsp;&nbsp;GBAMS Near by
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2063 left-icon"
                                        id="mega-menu-item-2063"
                                      >
                                        <MyLink
                                          slug="near-by-pious-spots-scenic-beauty"
                                          pageId="12"
                                          id="33"
                                          className="mega-menu-link"
                                        >
                                          Near By Pious spots & scenic
                                          beauty
                                        </MyLink>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-4049"
                                    id="mega-menu-item-4049"
                                  >
                                    <MyLink
                                      slug="learning-at-gbams"
                                      pageId="12"
                                      id="34"
                                      className="mega-menu-link"
                                    >
                                      <i className="fas fa-check-circle" />
                                      Learning at GBAMS
                                      <span className="mega-indicator" />
                                    </MyLink>
                                  </li>
                                </ul>
                              </li>
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-20-0-1"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2148"
                                    id="mega-menu-item-2148"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-video" />
                                      &nbsp;&nbsp;GBAMS Video's
                                      <span className="mega-indicator" />
                                    </Link>
                                    <ul className="mega-sub-menu">
                                      <li
                                        className="mega-left-icon mega-menu-item mega-menu-item-type-post_type mega-menu-item-object-page mega-menu-item-2106 left-icon"
                                        id="mega-menu-item-2106"
                                      >
                                        <Link
                                          className="mega-menu-link"
                                          to={`/gallery/video-gallery-category`}
                                          onClick={() => handleCardClick("video-gallery-category")}
                                        >
                                        
                                          <span className="elementor-icon-list-text">Video Gallery</span>
                                        </Link>
                                      </li>
                                    </ul>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2150"
                                    id="mega-menu-item-2150"
                                  >
                                    <Link className="mega-menu-link" to="/">
                                      <i className="fas fa-building" />
                                      Personality enrichment activities
                                      <span className="mega-indicator" />
                                    </Link>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2152"
                                    id="mega-menu-item-2152"
                                  >
                                    <MyLink
                                      slug="social-entrepreneurial-initiatives"
                                      pageId="12"
                                      id="37"
                                      className="mega-menu-link"
                                    >
                                      <i className="fas fa-building" />
                                      Social & entrepreneurial initiatives
                                      <span className="mega-indicator" />
                                    </MyLink>
                                  </li>
                                  <li
                                    className="mega-menu-item mega-menu-item-type-custom mega-menu-item-object-custom mega-menu-item-has-children mega-menu-item-2684"
                                    id="mega-menu-item-2684"
                                  >
                                    <MyLink
                                      slug="pre-placement-training-skill-buildingimmersive-experiences"
                                      pageId="12"
                                      id="38"
                                      className="mega-menu-link"
                                    >
                                      <i className="fas fa-graduation-cap" />
                                      Pre placement training & skill
                                      building(Immersive Experiences){" "}
                                      <span className="mega-indicator" />
                                    </MyLink>
                                  </li>
                                </ul>
                              </li>
                              <li
                                className="mega-menu-column mega-menu-columns-4-of-12"
                                id="mega-menu-20-0-2"
                              >
                                <ul className="mega-sub-menu">
                                  <li
                                    className="mega-menu-item mega-menu-item-type-widget widget_media_image mega-menu-item-media_image-4"
                                    id="mega-menu-item-media_image-4"
                                  >
                                    {fullImage1 && (
                                      <img
                                        width={800}
                                        height={534}
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                        decoding="async"
                                        src={fullImage1}
                                        className="image wp-image-1898 attachment-large size-large "
                                      />
                                    )}
                                    <noscript>
                                      <img
                                        width="800"
                                        height="534"
                                        alt="dynamic image"
                                        style={{
                                          maxWidth: "100%",
                                          height: "auto",
                                        }}
                                        src={fullImage1}
                                        className="image wp-image-1898 attachment-large size-large"
                                      />
                                    </noscript>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>

                      {/* 77777 */}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Header;
