import React, { createContext, useState, useEffect } from 'react';
import { BASE_URL, ENDPOINTS } from './Config';

const ApiContext = createContext();

// Create the provider component
const ApiProvider = ({ children }) => {
  const [BusinessSetting, setBusinessSetting] = useState(null);
  const [Home, setHome] = useState(null);
  const [Page, setPage] = useState(null);
  const [data4, setData4] = useState(null);
  const [Enquiry, setEnquiry] = useState(null);
  const [loading, setLoading] = useState(false);  
  const [error2, setError2] = useState(null);

  const fetchBusinessSetting = async () => {
    const cachedData = sessionStorage.getItem('businessSetting');
    if (cachedData) {
      setBusinessSetting(JSON.parse(cachedData));
      return; // Exit if data is cached
    }
  
    const response = await fetch(`${BASE_URL}${ENDPOINTS.BusinessSetting}`);
    const result = await response.json();
    sessionStorage.setItem('businessSetting', JSON.stringify(result));
    setBusinessSetting(result);
  };
  
  const fetchHome = async () => {
    const cachedData = sessionStorage.getItem('home');
    if (cachedData) {
      setHome(JSON.parse(cachedData));
      return; // Exit if data is cached
    }
  
    const response = await fetch(`${BASE_URL}${ENDPOINTS.Home}`);
    const result = await response.json();
    if (result.success) {
      sessionStorage.setItem('home', JSON.stringify(result));
      setHome(result);
    } else {
      setHome([]); 
      setError2(result.message); // Handle error2 scenario
    }
  };
  
  const fetchPage = async () => {
    const cachedData = sessionStorage.getItem('page');
    if (cachedData) {
      setPage(JSON.parse(cachedData));
      return; // Exit if data is cached
    }
  
    const response = await fetch(`${BASE_URL}${ENDPOINTS.Page}`);
    const result = await response.json();
    sessionStorage.setItem('page', JSON.stringify(result));
    setPage(result);
  };
  

  // // Function to fetch PageContent
  // const fetchPageContent = async () => {
  //   const response = await fetch(`${BASE_URL}${ENDPOINTS.PageContent}`);
  //   const result = await response.json();
  //   setData4(result);
  // };

  // // Function to fetch Enquiry
  // const fetchEnquiry = async () => {
  //   const response = await fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`);
  //   const result = await response.json();
  //   setEnquiry(result);
  // };

  // Function to fetch all data in parallel
  const fetchAllData = async () => {
    setLoading(true); // Set loading to true before starting all requests
    try {
      // Wait for all API requests to complete using Promise.all
      await Promise.all([
        fetchBusinessSetting(),
        fetchHome(),
        fetchPage(),
        // fetchPageContent(),
        // fetchEnquiry()
      ]);
    } catch (err) {
      setError2(err);
    } finally {
      setLoading(false); // Set loading to false after all requests finish
    }
  };

  // Optionally call `fetchAllData` inside useEffect if you want to load all data on mount
  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <ApiContext.Provider value={{ 
      BusinessSetting, 
      Home, 
      Page, 
      data4, 
      Enquiry, 
      loading, 
      error2, 
      fetchBusinessSetting, 
      fetchHome, 
      fetchPage, 
      // fetchPageContent, 
      // fetchEnquiry, 
      fetchAllData 
    }}>
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
