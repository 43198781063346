import React from "react";
import Tile from "./Tiles";
import "../css/Gallary.css";
import PhotoTile from "./PhotoTile";

const PhotoGallery = ({ data, slug }) => {
  return (
    <PhotoTile data={data} slug={slug} />

  );
};

export default PhotoGallery;
