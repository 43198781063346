import React from "react";
import Tile from "./Tiles";
import "../css/Gallary.css";
import PhotoTile from "./PhotoTile";

const Gallery = ({ data, slug }) => {
  return (
    <>
     <Tile data={data} slug={slug}/>


    </>

  );
};

export default Gallery;
