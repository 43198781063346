import React from "react";

const VideoGallery3 = ({ videos }) => {
  return (
    <div className="elementor-widget-container">
      <div className="ecs-posts elementor-posts-container elementor-posts elementor-grid elementor-posts--skin-custom">
        <article className="elementor-post elementor-grid-item ecs-post-loop">
          <div className="elementor">
            <section className="elementor-section elementor-top-section">

         
             
                <div className="row">
                  {videos.data.map((video, index) => (
                    <div className="col-md-6" key={index}>
                      <iframe
                        width="100%"
                        height="315"
                        src={video.video}
                        frameBorder="0"
                        allowFullScreen
                        title={`Video ${index}`}
                      ></iframe>
                    </div>
                  ))}
                </div>
             
            </section>
          </div>
        </article>
      </div>
    </div>
  );
};

export default VideoGallery3;
