import React, { useState } from 'react';
import '../css/Gallary.css'; 
import { ImageBASE_URL } from '../ApiContext/Config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate } from "react-router-dom";

const PhotoTile = ({ data ,slug}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = data.data.map((category) => ({
    title:category.title,
    id:category.id,
    src: category.image ? ImageBASE_URL + category.image : '/assets/placeholder.jpg', 
  }));

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };
 
  
  return (
    <div className="container">
      <div className="row">
        {images.length === 0 ? (
          <p>No images available</p>
        ) : (
          images.map((item, index) => (


            <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
            <div className="gallery_image_container m-1" >
              <img
                src={item.src}
                alt={item.title}
                className="img-responsive gallery_image"
                onClick={() => openLightbox(index)}
              />
            </div>
            
          </div>
          ))
        )}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default PhotoTile;