import React, { useEffect, useState, useContext } from "react";
import Swal from 'sweetalert2';
import {
  UncontrolledCarousel,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import { ApiContext } from "../ApiContext/ApiContext";
import { BASE_URL, ENDPOINTS, ImageBASE_URL } from "../ApiContext/Config";
import { Link } from "react-router-dom";
import "./CustomCarousel.css";
import PopupMain from "./PopupMain";

const CustomCarousel = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  console.log("===activeIndex=======" + activeIndex);
  const { BusinessSetting, Home, loading, error } = useContext(ApiContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    qualification: "",
    city: "",
  });

  useEffect(() => {
    // Fetch data from API on first load
    const fetchData = async () => {
      if (
        BusinessSetting.data.popup_status == 1
      ) {
        setShowPopup(true);

      }
    };

    fetchData();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSlideChange = (index) => {
 
    setActiveIndex(index);
  };
  const items =
    Home && Home.data
      ? Home.data.sliders.map((slide, index) => ({
        altText: `${index + 1}`,
        caption: ``,
        key: index + 1,
        src: ImageBASE_URL + slide.image, // Assuming 'image' is the property name for image URL in your data
      }))
      : [];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, [items.length]);
  console.log(items);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  
    try {
      const response = await fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      Swal.fire({
        title: "Success",
        text: "Form submitted successfully",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Error submitting form",
        icon: "error",
      });
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      qualification: "",
      city: "",
    });
  };
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <div style={{ position: "relative" }}>
      {loading && <div>Loading...</div>}
      {error && <div>Error loading data</div>}
      {!loading && !error && (
        <>
          {/* Title and Home Button */}

          {/* Inquiry Form */}
          <div
            style={{
              position: "absolute",
              right: "20px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 2,
            }}
          >
            <Form>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-338e51a elementor-hidden-tablet elementor-hidden-mobile"
                data-id="338e51a"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-a035775 slide-form elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="a035775"
                    data-element_type="section"
                    data-settings='{"background_background":"classic","shape_divider_top":"triangle"}'
                  >
                    <div
                      className="elementor-shape elementor-shape-top"
                      data-negative="false"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1000 100"
                        preserveAspectRatio="none"
                      >
                        <path
                          className="elementor-shape-fill"
                          d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"
                        />
                      </svg>
                    </div>
                    <div className="elementor-container elementor-column-gap-default">
                      <div
                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a15ac86"
                        data-id="a15ac86"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-805c779 elementor-widget elementor-widget-text-editor"
                            data-id="805c779"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container text-dark">
                              <p className="text-center text-dark">
                                Admissions Enquiry&nbsp;
                                <br />
                                2024-26
                              </p>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-99b7e80 elementor-hidden-tablet elementor-hidden-mobile elementor-widget elementor-widget-shortcode"
                            data-id="99b7e80"
                            data-element_type="widget"
                            data-widget_type="shortcode.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-shortcode">
                                <div
                                  className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework"
                                  data-form-theme="gravity-theme"
                                  data-form-index={0}
                                  id="gform_wrapper_5"
                                >
                                  <div
                                    id="gf_5"
                                    className="gform_anchor"
                                    tabIndex={-1}
                                  />

                                  <form onSubmit={handleSubmit}>
                                    <div className="gform-body gform_body">
                                      <div
                                        id="gform_fields_5"
                                        className="gform_fields top_label form_sublabel_below description_below"
                                      >
                                        <div
                                          id="field_5_1"
                                          className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                          data-js-reload="field_5_1"
                                        >
                                          <label
                                            className="gfield_label gform-field-label"
                                            htmlFor="input_5_1"
                                          >
                                            Name
                                            <span className="gfield_required">
                                              <span className="gfield_required gfield_required_text">
                                                (Required)
                                              </span>
                                            </span>
                                          </label>
                                          <div className="ginput_container ginput_container_text">
                                            <input
                                              className="large"
                                              name="name"
                                              type="text"
                                              value={formData.name}
                                              onChange={handleChange}
                                              placeholder="Full Name"
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div
                                          id="field_5_2"
                                          className="gfield gfield--type-email gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                          data-js-reload="field_5_2"
                                        >
                                          <label
                                            className="gfield_label gform-field-label"
                                            htmlFor="input_5_2"
                                          >
                                            Email Address
                                            <span className="gfield_required">
                                              <span className="gfield_required gfield_required_text">
                                                (Required)
                                              </span>
                                            </span>
                                          </label>
                                          <div className="ginput_container ginput_container_email">
                                            <input
                                              className="large"
                                              name="email"
                                              type="email"
                                              value={formData.email}
                                              onChange={handleChange}
                                              placeholder="Email Address"
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div
                                          id="field_5_5"
                                          className="gfield gfield--type-phone gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                          data-js-reload="field_5_5"
                                        >
                                          <label
                                            className="gfield_label gform-field-label"
                                            htmlFor="input_5_5"
                                          >
                                            Phone Number
                                            <span className="gfield_required">
                                              <span className="gfield_required gfield_required_text">
                                                (Required)
                                              </span>
                                            </span>
                                          </label>
                                          <div className="ginput_container ginput_container_phone">
                                            <input
                                              className="large"
                                              name="phone"
                                              type="tel"
                                              value={formData.phone}
                                              onChange={handleChange}
                                              placeholder="Phone Number"
                                              required
                                            />
                                          </div>
                                        </div>
                                        <div
                                          id="field_5_6"
                                          className="gfield gfield--type-select gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                          data-js-reload="field_5_6"
                                        >
                                          <label
                                            className="gfield_label gform-field-label"
                                            htmlFor="input_5_6"
                                          >
                                            Your Current Qualification
                                            <span className="gfield_required">
                                              <span className="gfield_required gfield_required_text">
                                                (Required)
                                              </span>
                                            </span>
                                          </label>
                                          <div className="ginput_container ginput_container_select">
                                            <select
                                              name="qualification"
                                              value={formData.qualification}
                                              onChange={handleChange}
                                              className="large gfield_select"
                                              aria-required="true"
                                              required
                                            >
                                              <option
                                                value
                                                selected="selected"
                                                className="gf_placeholder"
                                              >
                                                Your Current Qualification
                                              </option>

                                              <option value="B.A.">B.A.</option>
                                              <option value="B.Sc.">
                                                B.Sc.
                                              </option>
                                              <option value="B.Com">
                                                B.Com
                                              </option>
                                              <option value="BCA">BCA</option>
                                              <option value="B.Tech">
                                                B.Tech
                                              </option>
                                              <option value="Commerce">
                                                Commerce (10+2)
                                              </option>
                                              <option value="Science">
                                                Science (10+2)
                                              </option>
                                              <option value="Arts">
                                                Arts (10+2)
                                              </option>
                                              <option value="Any other">
                                                Any other
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                        <div
                                          id="field_5_7"
                                          className="gfield gfield--type-text gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                          data-js-reload="field_5_7"
                                        >
                                          <label
                                            className="gfield_label gform-field-label"
                                            htmlFor="input_5_7"
                                          >
                                            City
                                          </label>
                                          <div className="ginput_container ginput_container_text">
                                            <input
                                              ype="text"
                                              defaultValue
                                              className="large"
                                              name="city"
                                              type="text"
                                              value={formData.city}
                                              onChange={handleChange}
                                              placeholder="Location"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="gform_footer top_label">
                                      <input
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="gform_button button large w-100"
                                        defaultValue="Get a call back"
                                        value="Get a call back"
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Form>
          </div>

          {/* Carousel */}
          <div className="custom-carousel " style={{ position: "relative" }}>
            <UncontrolledCarousel
              items={items}
              activeIndex={activeIndex}
              onSlideIndexChange={handleSlideChange}
              interval={null}
            />

            <div
              className="carousel-overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#02353D",
                opacity: ".6",
                zIndex: 1,
              }}
            >
              {Home && Home.data
                ? Home.data.sliders.map((item, index) => (
                  <div
                    style={{
                      position: "absolute",
                      left: "80px",
                      bottom: "100px",
                      zIndex: 2,
                      display: index === activeIndex ? "block" : "none",
                    }}
                    key={index}
                  >
                    <div
                      className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-2f42923"
                      data-id="2f42923"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-f730a6d elementor-widget elementor-widget-spacer"
                          data-id="f730a6d"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-8ac5bca elementor-widget elementor-widget-spacer"
                          data-id="8ac5bca"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner" />
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-3baea48 elementor-widget elementor-widget-heading"
                          data-id="3baea48"
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">
                              {item.title}
                            </h2>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-db770a6 elementor-widget elementor-widget-text-editor"
                          data-id="db770a6"
                          data-element_type="widget"
                          data-widget_type="text-editor.default"
                        >
                          <div className="elementor-widget-container">
                            <p>{item.subtitle}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
                : ""}
            </div>

            <button
              style={{
                position: "absolute",
                top: "50%",
                left: "0",
                transform: "translateY(-50%)",
                width: "20px",
                height: "100%",
                zIndex: 2,
                opacity: 0,
                cursor: "pointer",
              }}
              onClick={handleSlideChange}
            />
          </div>
          <PopupMain show={showPopup} onClose={handleClosePopup} />
        </>
      )}
    </div>
  );
};

export default CustomCarousel;



