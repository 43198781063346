import { Link } from 'react-router-dom';
import { ApiContext } from '../ApiContext/ApiContext';
import { ImageBASE_URL } from '../ApiContext/Config';
import React, { useEffect, useState, useContext } from 'react';
import PopupForm from './EnquiryForm';
import MyLink from "./MyLink";
import { useNavigate } from "react-router-dom";
function Footer({ BusinessSetting }) {
  const navigate = useNavigate();


  const handleLink2 = (slug, pageId, id, title) => {
    const state = {
      pageId: pageId,
      id: id,
      title: title,
      slug: slug,
    };
    navigate(`/${slug}`, { state });
  };


  const handleCardClick = (slug) => {

    navigate(`/gallery/${slug}`);
  };

  const fullImageUrl =
    BusinessSetting && BusinessSetting.data
      ? `${ImageBASE_URL}${BusinessSetting.data.footer_logo}`
      : "";
  return (
    <>
      {BusinessSetting ? (
        <div
          data-elementor-type="footer"
          data-elementor-id="113"
          className="elementor elementor-113 elementor-location-footer"
        >
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-8e3ef92 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="8e3ef92"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-background-overlay"></div>
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-5c7e82d"
                data-id="5c7e82d"
                data-element_type="column"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-8668919 elementor-widget elementor-widget-image"
                    data-id="8668919"
                    data-element_type="widget"
                    data-widget_type="image.default"
                  >
                    <div className="elementor-widget-container">
                      {fullImageUrl && (
                        <img
                          width="1000"
                          height="203"
                          alt="pgdm colleges in up"
                          src={fullImageUrl}
                          srcSet={`${fullImageUrl} 1000w, ${fullImageUrl} 300w, ${fullImageUrl} 768w`}
                          sizes="(max-width: 1000px) 100vw, 1000px"
                          className="attachment-full size-full"
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-ccd91c4 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="ccd91c4"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-map-marker-alt"
                            ></i>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            {BusinessSetting && BusinessSetting.data
                              ? `${BusinessSetting.data.address}`
                              : ""}
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i
                              aria-hidden="true"
                              className="fas fa-phone-alt"
                            ></i>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            {" "}
                            {BusinessSetting && BusinessSetting.data
                              ? `${BusinessSetting.data.phone_no1}`
                              : ""}{" "}
                            |
                            {BusinessSetting && BusinessSetting.data
                              ? `${BusinessSetting.data.phone_no1}`
                              : ""}
                          </span>
                        </li>
                        <li className="elementor-icon-list-item">
                          <span className="elementor-icon-list-icon">
                            <i aria-hidden="true" className="far fa-envelope"></i>{" "}
                          </span>
                          <span className="elementor-icon-list-text">
                            {BusinessSetting && BusinessSetting.data
                              ? `${BusinessSetting.data.email_id1}`
                              : ""}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f6a5d2a elementor-widget elementor-widget-heading"
                    data-id="f6a5d2a"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        FOLLOW US ON
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f0ce3d3 e-grid-align-left elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
                    data-id="f0ce3d3"
                    data-element_type="widget"
                    data-widget_type="social-icons.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-social-icons-wrapper elementor-grid">
                        <span className="elementor-grid-item">
                          <Link
                            className="elementor-icon elementor-social-icon elementor-social-icon-facebook elementor-repeater-item-478c920"
                            to={`https://www.facebook.com/profile.php?id=${BusinessSetting && BusinessSetting.data
                              ? `${BusinessSetting.data.facebook}`
                              : ""
                              }`}
                            target="_blank"
                          >
                            <span className="elementor-screen-only">
                              Facebook
                            </span>
                            <i className="fab fa-facebook"></i>{" "}
                          </Link>
                        </span>
                        <span className="elementor-grid-item">
                          <Link
                            className="elementor-icon elementor-social-icon elementor-social-icon-linkedin elementor-repeater-item-7a71095"
                            to={
                              BusinessSetting && BusinessSetting.data
                                ? `${BusinessSetting.data.linkedin}`
                                : ""
                            }
                            target="_blank"
                          >
                            <span className="elementor-screen-only">
                              Linkedin
                            </span>
                            <i className="fab fa-linkedin"></i>{" "}
                          </Link>
                        </span>
                        <span className="elementor-grid-item">
                          <Link
                            className="elementor-icon elementor-social-icon elementor-social-icon-instagram elementor-repeater-item-da00062"
                            to={
                              BusinessSetting && BusinessSetting.data
                                ? `${BusinessSetting.data.instagram}`
                                : ""
                            }
                            target="_blank"
                          >
                            <span className="elementor-screen-only">
                              Instagram
                            </span>
                            <i className="fab fa-instagram"></i>{" "}
                          </Link>
                        </span>
                        <span className="elementor-grid-item">
                          <Link
                            className="elementor-icon elementor-social-icon elementor-social-icon-twitter elementor-repeater-item-0f9b320"
                            to={
                              BusinessSetting && BusinessSetting.data
                                ? `${BusinessSetting.data.twitter}`
                                : ""
                            }
                            target="_blank"
                          >
                            <span className="elementor-screen-only">Twitter</span>
                            <i className="fab fa-twitter"></i>{" "}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-91093fb"
                data-id="91093fb"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-4386a21 elementor-widget elementor-widget-heading"
                    data-id="4386a21"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        Our Academy
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-8eecb12 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="8eecb12"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <MyLink slug="binani-group" pageId="8" id="4">
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              About GBAMS
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="vision-mission-objectives"
                            pageId="8"
                            id="2"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Vision, Mission & Objectives
                            </span>
                          </MyLink>
                        </li>

                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="binani-group"
                            pageId="8"
                            id="4"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Our Legacy
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="near-by-pious-spots-scenic-beauty"
                            pageId="12"
                            id="33"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Near by pious Sights & Scenic beauty
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">


                          <Link
                            className="mega-menu-link"
                            to={`/gallery/photo-gallery-category`}
                            onClick={() => handleCardClick("photo-gallery-category")}
                        
                            style={{ cursor: 'pointer' }} 
                          >
                            <span className="elementor-icon-list-icon">
                              <i aria-hidden="true" className="fas fa-angle-double-right"></i>
                            </span>
                            <span className="elementor-icon-list-text">Photo Gallery</span>
                          </Link>
                        </li>
                        <li className="elementor-icon-list-item">
                          <Link
                            className="mega-menu-link"
                            to={`/gallery/video-gallery-category`}
                            onClick={() => handleCardClick("video-gallery-category")}
                          >
                            <span className="elementor-icon-list-icon">
                              <i aria-hidden="true" className="fas fa-angle-double-right"></i>
                            </span>
                            <span className="elementor-icon-list-text">Video Gallery</span>
                          </Link>
                        </li>

                        <li
                          className="elementor-icon-list-item"
                          onClick={() =>
                            handleLink2(`alumni-list`, `555`, "1", `Alumni`)
                          }
                        >
                          <Link className="mega-menu-link">
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Our Alumni
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ce5979a"
                data-id="ce5979a"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-f6d29ae elementor-widget elementor-widget-heading"
                    data-id="f6d29ae"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        Academic Programe
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-eb4ebd5 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="eb4ebd5"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="mbabba-programme"
                            pageId="9"
                            id="13"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              MBA/BBA Program
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="mbabba-course-structure"
                            pageId="9"
                            id="14"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              MBA/BBA Course Structure
                            </span>
                          </MyLink>
                        </li>

                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="admission-process"
                            pageId="9"
                            id="5"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              MBA/BBA Admission Process
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="eligibility-fees-details"
                            pageId="9"
                            id="18"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Eligibility & Fee Details
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <Link
                            to="http://samajkalyan.up.gov.in/"
                            target="_blank"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Scholarship Policy
                            </span>
                          </Link>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="gbams-edge-over-others"
                            pageId="9"
                            id="19"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Prospective Advantages
                            </span>
                          </MyLink>
                        </li>

                        <li className="elementor-icon-list-item">
                          <Link
                            to="https://www.gbamsvidushi.com/"
                            target="_blank"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              GBAMS Vidushi
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-c858bdd"
                data-id="c858bdd"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-6c58449 elementor-widget elementor-widget-heading"
                    data-id="6c58449"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        Placement
                      </span>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-fae4f5b elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="fae4f5b"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items">
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="placement-process-calendar"
                            pageId="10"
                            id="21"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              GBAMS Placement Process
                            </span>
                          </MyLink>
                        </li>

                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="students-profile"
                            pageId="10"
                            id="25"

                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Student's Profile
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink slug="placement-report" pageId="10" id="22">
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Placement Report
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="summer-internship-details"
                            pageId="10"
                            id="26"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Summer Internship Details
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink slug="our-recruiters" pageId="10" id="23">
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Our Recruiters
                            </span>
                          </MyLink>
                        </li>
                        <li className="elementor-icon-list-item">
                          <Link  to="/industry-speak"   className="mega-menu-link" >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Industry Speaks
                            </span>
                          </Link>

                         
                        </li>
                        <li className="elementor-icon-list-item">
                          <MyLink
                            slug="learning-at-gbams"
                            pageId="12"
                            id="34"
                            className="mega-menu-link"
                          >
                            <span className="elementor-icon-list-icon">
                              <i
                                aria-hidden="true"
                                className="fas fa-angle-double-right"
                              ></i>{" "}
                            </span>
                            <span className="elementor-icon-list-text">
                              Learning at GBAMS
                            </span>
                          </MyLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f8a5e77 fix-btn-right elementor-widget elementor-widget-button"
                    data-id="f8a5e77"
                    data-element_type="widget"
                    data-widget_type="button.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-button-wrapper">
                        <PopupForm />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-86a7c9d e-grid-align-right fixed-whatsapp elementor-shape-rounded elementor-grid-0 elementor-widget elementor-widget-social-icons"
                    data-id="86a7c9d"
                    data-element_type="widget"
                    data-widget_type="social-icons.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-social-icons-wrapper elementor-grid">
                        <span className="elementor-grid-item">
                          <Link
                            className="elementor-icon elementor-social-icon elementor-social-icon-whatsapp elementor-repeater-item-69f89a1"
                            to={`https://wa.me/${BusinessSetting && BusinessSetting.data
                              ? `${BusinessSetting.data.phone_no1}`
                              : ""
                              }`}
                            target="_blank"
                          >
                            <span className="elementor-screen-only">
                              Whatsapp
                            </span>
                            <i className="fab fa-whatsapp"></i>{" "}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-09b4d4a elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="09b4d4a"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-24006bf"
                data-id="24006bf"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-70f3de8 elementor-icon-list--layout-inline elementor-mobile-align-center elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                    data-id="70f3de8"
                    data-element_type="widget"
                    data-widget_type="icon-list.default"
                  >
                    <div className="elementor-widget-container">
                      <ul className="elementor-icon-list-items elementor-inline-items">
                        <li className="elementor-icon-list-item elementor-inline-item">
                          <Link to="#">
                            <span className="elementor-icon-list-text">
                              Site Map
                            </span>
                          </Link>
                        </li>
                        <li className="elementor-icon-list-item elementor-inline-item">
                          <Link to="#">
                            <span className="elementor-icon-list-text">
                              Route Map
                            </span>
                          </Link>
                        </li>

                       
                        <li className="elementor-icon-list-item elementor-inline-item">
                         

                          <MyLink
                            slug="career"
                            pageId="14"
                            id="72"
                            className=""
                          >
                           
                            <span className="elementor-icon-list-text">
                            Career
                            </span>
                          </MyLink>
                        </li>

                        <li className="elementor-icon-list-item elementor-inline-item">
                          <span className="elementor-icon-list-text">
                            Developed By{" "}
                            <Link
                              to="http://www.maxmultisoft.com/"
                              target="_blank"
                            >
                              maxmultisoft.com
                            </Link>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1c570f1"
                data-id="1c570f1"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-ace25b5 elementor-widget elementor-widget-heading"
                    data-id="ace25b5"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <span className="elementor-heading-title elementor-size-default">
                        {BusinessSetting && BusinessSetting.data
                          ? `${BusinessSetting.data.copyright}`
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : null}


    </>
  );
}

export default Footer;