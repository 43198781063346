import React, { useEffect, useState, useContext } from "react";
import Footer from "../component/Footer";
import { BASE_URL, ENDPOINTS, ImageBASE_URL } from "../ApiContext/Config";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import { ApiContext } from "../ApiContext/ApiContext";
import "../Contact.css"
import Swal from "sweetalert2";
import MyLink from "../component/MyLink";

const ContactUs = () => {
  const { 
    BusinessSetting, 
    Page, 
    Home, 
    loading, 
    error2, 
    fetchAllData, 
  
  } = useContext(ApiContext);

  useEffect(() => {
 
    fetchAllData();
  
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    qualification: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      Swal.fire({
        title: "Success",
        text: "Form submitted successfully",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Error submitting form",
        icon: "error",
      });
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      qualification: "",
      city: "",
    });
  };

  const [formData2, setFormData2] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",

    type: "contact",
    subject: "",
    message: "",
  });

  const handleChange2 = (e) => {
 
    const { name, value } = e.target;
    setFormData2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData2),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      Swal.fire({
        title: "Success",
        text: "Form submitted successfully",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Error submitting form",
        icon: "error",
      });
    }
    setFormData({
      name: "",
      email: "",
      phone: "",
      qualification: "",
      city: "",
    });
  };
  const fullImage1 =
    BusinessSetting && BusinessSetting.data
      ? `${ImageBASE_URL}${BusinessSetting.data.image1}`
      : "";
  const fullImage2 =
    BusinessSetting && BusinessSetting.data
      ? `${ImageBASE_URL}${BusinessSetting.data.image2}`
      : "";



  return (
  
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f0f0',
          }}
        >
          <img
            src="/assets/favicon.png"
            alt="Loading"
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        </div>
      ) : error2 ? (
        <p>{error2}</p>
      ) : BusinessSetting ? (

        <div className="page-template page-template-page-full page-template-page-full-php page page-id-1041 user-registration-page mega-menu-main-menu elementor-default elementor-kit-5 elementor-page elementor-page-1041">
          <div
            data-elementor-type="header"
            data-elementor-id="8"
            className="elementor elementor-8 elementor-location-header"
          >

            {BusinessSetting ? <Header BusinessSetting={BusinessSetting} Page={Page} /> : null}
          </div>
      <div
        data-elementor-type="section"
        data-elementor-id="1208"
        className="elementor elementor-1208"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-68d8217d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="68d8217d"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6d3a34c6"
              data-id="6d3a34c6"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap"></div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-ff48016 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="ff48016"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-27671cf"
              data-id="27671cf"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-84eb36b elementor-icon-list--layout-inline elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                  data-id="84eb36b"
                  data-element_type="widget"
                  data-widget_type="icon-list.default"
                >
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items elementor-inline-items">
                      <li className="elementor-icon-list-item elementor-inline-item">
                        <a href="#">
                          <span className="elementor-icon-list-icon">
                            <i aria-hidden="true" className="fas fa-home"></i>{" "}
                          </span>
                          <span className="elementor-icon-list-text"></span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item elementor-inline-item">
                        <a href="#">
                          <span className="elementor-icon-list-text">
                            Contact Us{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-32ba1f2"
              data-id="32ba1f2"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap"></div>
            </div>
          </div>
        </section>
      </div>
      <div
        id="post-1896"
        className="content post-1896 page type-page status-publish has-post-thumbnail hentry"
      >
        <div className="row inner-page-fullwidth">
          <div className="col-md-3 hide-mobile">
            <div
              data-elementor-type="section"
              data-elementor-id="1138"
              className="elementor elementor-1138"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-62f12bb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="62f12bb"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4ecf5da sidebar-nav"
                    data-id="4ecf5da"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-21e7b52 elementor-widget elementor-widget-heading"
                        data-id="21e7b52"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-heading-title elementor-size-default">
                            Admission
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-10a1c4f elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu"
                        data-id="10a1c4f"
                        data-element_type="widget"
                        data-settings='{"layout":"vertical","submenu_icon":{"value":"&lt;i className=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"}}'
                        data-widget_type="nav-menu.default"
                      >
                        <div className="elementor-widget-container">
                          <nav
                            migration_allowed="1"
                            migrated="0"
                            role="navigation"
                            className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-background e--animation-fade"
                          >
                            <ul
                              id="menu-1-10a1c4f"
                              className="elementor-nav-menu sm-vertical"
                            >
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="mbabba-programme"
                                  pageId="9"
                                  id="13"
                                  className="elementor-item"
                                >
                                  MBA/BBA Program
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="mbabba-course-structure"
                                  pageId="9"
                                  id="14"
                                  className="elementor-item"
                                >
                                  MBA/BBA Course Structure
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="admission-process"
                                  pageId="9"
                                  id="5"
                                  className="elementor-item"
                                >
                                  MBA/BBA Admission Process
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="eligibility-fees-details"
                                  pageId="9"
                                  id="18"
                                  className="elementor-item"
                                >
                                  Eligibility & Fee Details
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <Link
                                  to="http://samajkalyan.up.gov.in/"
                                  target="_blank"
                                  className="elementor-item"
                                >
                                  Scholarship Policy
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="gbams-edge-over-others"
                                  pageId="9"
                                  id="19"
                                  className="elementor-item"
                                >
                                  Prospective Advantages
                                </MyLink>
                              </li>

                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <Link
                                  to="https://www.gbamsvidushi.com/"
                                  target="_blank"
                                  className="elementor-item"
                                >
                                  GBAMS Vidushi
                                </Link>
                              </li>
                            </ul>
                          </nav>
                          <div
                            className="elementor-menu-toggle"
                            role="button"
                            tabindex="0"
                            aria-label="Menu Toggle"
                            aria-expanded="false"
                          >
                            <i
                              aria-hidden="true"
                              role="presentation"
                              className="elementor-menu-toggle__icon--open eicon-menu-bar"
                            ></i>
                            <i
                              aria-hidden="true"
                              role="presentation"
                              className="elementor-menu-toggle__icon--close eicon-close"
                            ></i>{" "}
                            <span className="elementor-screen-only">Menu</span>
                          </div>
                          <nav
                            className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                            role="navigation"
                            aria-hidden="true"
                          >
                            <ul
                              id="menu-2-10a1c4f"
                              className="elementor-nav-menu sm-vertical"
                            >
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="mbabba-programme"
                                  pageId="9"
                                  id="13"
                                  className="elementor-item"
                                >
                                  MBA/BBA Program
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="mbabba-course-structure"
                                  pageId="9"
                                  id="14"
                                  className="elementor-item"
                                >
                                  MBA/BBA Course Structure
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="admission-process"
                                  pageId="9"
                                  id="5"
                                  className="elementor-item"
                                >
                                  MBA/BBA Admission Process
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="eligibility-fees-details"
                                  pageId="9"
                                  id="18"
                                  className="elementor-item"
                                >
                                  Eligibility & Fee Details
                                </MyLink>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <Link
                                  to="http://samajkalyan.up.gov.in/"
                                  target="_blank"
                                  className="elementor-item"
                                >
                                  Scholarship Policy
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <MyLink
                                  slug="gbams-edge-over-others"
                                  pageId="9"
                                  id="19"
                                  className="elementor-item"
                                >
                                  Prospective Advantages
                                </MyLink>
                              </li>

                              <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-5420">
                                <Link
                                  to="https://www.gbamsvidushi.com/"
                                  target="_blank"
                                  className="elementor-item"
                                >
                                  GBAMS Vidushi
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-ff3a0b9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="ff3a0b9"
                        data-element_type="section"
                        data-settings='{"background_background":"classic"}'
                      >
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a69ca49"
                            data-id="a69ca49"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-1e1c548 elementor-widget elementor-widget-heading"
                                data-id="1e1c548"
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-heading-title elementor-size-default">
                                    Contact Us
                                  </div>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-343ebb1 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                data-id="343ebb1"
                                data-element_type="widget"
                                data-widget_type="icon-list.default"
                              >
                                <div className="elementor-widget-container">
                                  <ul className="elementor-icon-list-items">
                                    <li className="elementor-icon-list-item">
                                      <span className="elementor-icon-list-icon">
                                        <i
                                          aria-hidden="true"
                                          className="far fa-envelope"
                                        ></i>{" "}
                                      </span>
                                      <span className="elementor-icon-list-text">
                                        {BusinessSetting && BusinessSetting.data
                                          ? BusinessSetting.data.email_id1
                                          : ""}
                                      </span>
                                    </li>
                                    <li className="elementor-icon-list-item">
                                      <span className="elementor-icon-list-icon">
                                        <i
                                          aria-hidden="true"
                                          className="fas fa-phone-alt"
                                        ></i>{" "}
                                      </span>
                                      <span className="elementor-icon-list-text">
                                        Office Tel:{" "}
                                        {BusinessSetting && BusinessSetting.data
                                          ? BusinessSetting.data.phone_no1
                                          : ""}
                                           <br/>
                                        {BusinessSetting && BusinessSetting.data
                                          ? BusinessSetting.data.phone_no2
                                          : ""}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section
                        className="elementor-section elementor-inner-section elementor-element elementor-element-8dbc4f4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="8dbc4f4"
                        data-element_type="section"
                        data-settings='{"background_background":"classic","shape_divider_top":"triangle"}'
                      >
                        <div
                          className="elementor-shape elementor-shape-top"
                          data-negative="false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1000 100"
                            preserveAspectRatio="none"
                          >
                            <path
                              className="elementor-shape-fill"
                              d="M500,98.9L0,6.1V0h1000v6.1L500,98.9z"
                            />
                          </svg>
                        </div>
                        <div className="elementor-container elementor-column-gap-default">
                          <div
                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-db45aec"
                            data-id="db45aec"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated">
                              <div
                                className="elementor-element elementor-element-10563c8 elementor-widget elementor-widget-text-editor"
                                data-id="10563c8"
                                data-element_type="widget"
                                data-widget_type="text-editor.default"
                              >
                                <div className="elementor-widget-container">
                                  <p className="text-center text-dark">
                                    Admissions Enquiry&nbsp;
                                    <br />
                                    2024-26
                                  </p>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-a0a1db0 elementor-widget elementor-widget-shortcode"
                                data-id="a0a1db0"
                                data-element_type="widget"
                                data-widget_type="shortcode.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-shortcode">
                                    <script type="text/javascript"></script>
                                    <div
                                      className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework"
                                      data-form-theme="gravity-theme"
                                      data-form-index="0"
                                      id="gform_wrapper_5"
                                    >
                                      <div
                                        id="gf_5"
                                        className="gform_anchor"
                                        tabindex="-1"
                                      ></div>

                                      <form
                                        method="post"
                                        encType="multipart/form-data"
                                        onSubmit={handleSubmit}
                                      >
                                        <div className="gform-body gform_body">
                                          <div
                                            id="gform_fields_5"
                                            className="gform_fields top_label form_sublabel_below description_below"
                                          >
                                            <div
                                              id="field_5_1"
                                              className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                              data-js-reload="field_5_1"
                                            >
                                              <label
                                                className="gfield_label gform-field-label"
                                                htmlFor="input_5_1"
                                              >
                                                Name
                                                <span className="gfield_required">
                                                  <span className="gfield_required gfield_required_text">
                                                    (Required)
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="ginput_container ginput_container_text">
                                                <input
                                                  className="large"
                                                  type="text"
                                                  name="name"
                                                  value={formData.name}
                                                  onChange={handleChange}
                                                  placeholder="Full Name"
                                                  required
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="field_5_2"
                                              className="gfield gfield--type-email gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                              data-js-reload="field_5_2"
                                            >
                                              <label
                                                className="gfield_label gform-field-label"
                                                htmlFor="input_5_2"
                                              >
                                                Email Address
                                                <span className="gfield_required">
                                                  <span className="gfield_required gfield_required_text">
                                                    (Required)
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="ginput_container ginput_container_email">
                                                <input
                                                  className="large"
                                                  name="email"
                                                  type="email"
                                                  value={formData.email}
                                                  onChange={handleChange}
                                                  placeholder="Email Address"
                                                  required
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="field_5_5"
                                              className="gfield gfield--type-phone gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                              data-js-reload="field_5_5"
                                            >
                                              <label
                                                className="gfield_label gform-field-label"
                                                htmlFor="input_5_5"
                                              >
                                                Phone Number
                                                <span className="gfield_required">
                                                  <span className="gfield_required gfield_required_text">
                                                    (Required)
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="ginput_container ginput_container_phone">
                                                <input
                                                  className="large"
                                                  name="phone"
                                                  type="tel"
                                                  value={formData.phone}
                                                  onChange={handleChange}
                                                  placeholder="Phone Number"
                                                  required
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="field_5_6"
                                              className="gfield gfield--type-select gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                              data-js-reload="field_5_6"
                                            >
                                              <label
                                                className="gfield_label gform-field-label"
                                                htmlFor="input_5_6"
                                              >
                                                Your Current Qualification
                                                <span className="gfield_required">
                                                  <span className="gfield_required gfield_required_text">
                                                    (Required)
                                                  </span>
                                                </span>
                                              </label>
                                              <div className="ginput_container ginput_container_select">
                                                <select
                                                  name="qualification"
                                                  value={formData.qualification}
                                                  onChange={handleChange}
                                                  className="large gfield_select"
                                                  aria-required="true"
                                                  required
                                                >
                                                  <option
                                                    value
                                                    selected="selected"
                                                    className="gf_placeholder"
                                                  >
                                                    Your Current Qualification
                                                  </option>

                                                  <option value="B.A.">
                                                    B.A.
                                                  </option>
                                                  <option value="B.Sc.">
                                                    B.Sc.
                                                  </option>
                                                  <option value="B.Com">
                                                    B.Com
                                                  </option>
                                                  <option value="BCA">
                                                    BCA
                                                  </option>
                                                  <option value="B.Tech">
                                                    B.Tech
                                                  </option>
                                                  <option value="Commerce">
                                                    Commerce (10+2)
                                                  </option>
                                                  <option value="Science">
                                                    Science (10+2)
                                                  </option>
                                                  <option value="Arts">
                                                    Arts (10+2)
                                                  </option>
                                                  <option value="Any other">
                                                    Any other
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div
                                              id="field_5_7"
                                              className="gfield gfield--type-text gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                              data-js-reload="field_5_7"
                                            >
                                              <label
                                                className="gfield_label gform-field-label"
                                                htmlFor="input_5_7"
                                              >
                                                City
                                              </label>
                                              <div className="ginput_container ginput_container_text">
                                                <input
                                                  ype="text"
                                                  defaultValue
                                                  className="large"
                                                  name="city"
                                                  type="text"
                                                  value={formData.city}
                                                  onChange={handleChange}
                                                  placeholder="Location"
                                                />
                                              </div>
                                            </div>
                                            <div
                                              id="field_5_8"
                                              className="gfield gfield--type-captcha gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                              data-js-reload="field_5_8"
                                            >
                                              <label
                                                className="gfield_label gform-field-label"
                                                htmlFor="input_5_8"
                                              >
                                                CAPTCHA
                                              </label>
                                              <div
                                                id="input_5_8"
                                                className="ginput_container ginput_recaptcha"
                                                data-sitekey="6Le33jYgAAAAAHEf7cxacf3DC9xqhEEqAIWisWwH"
                                                data-theme="light"
                                                data-tabindex={-1}
                                                data-size="invisible"
                                                data-badge="bottomright"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="gform_footer top_label">
                                          <input
                                            type="submit"
                                            id="gform_submit_button_5"
                                            className="gform_button button large w-100"
                                            defaultValue="Get a call back!"
                                            value="Get a call back"
                                          />
                                        </div>
                                      </form>
                                    </div>
                                    <iframe
                                      data-lazyloaded="1"
                                      src="about:blank"
                                      style={{
                                        display: "none",
                                        width: "0px",
                                        height: "0px",
                                      }}
                                      data-src="about:blank"
                                      name="gform_ajax_frame_5"
                                      id="gform_ajax_frame_5"
                                      title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                                    ></iframe>
                                    <noscript>
                                      <iframe
                                        style={{
                                          display: "none",
                                          width: "0px",
                                          height: "0px",
                                        }}
                                        src="about:blank"
                                        name="gform_ajax_frame_5"
                                        id="gform_ajax_frame_5"
                                        title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                                      ></iframe>
                                    </noscript>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="col-md-9">
            <div className="page-title-2">
              <h2 className="elementor-heading-title elementor-size-default">
                Contact Us
              </h2>
              <div className="elementor-divider">
                <span className="elementor-divider-separator"> </span>
              </div>
            </div>
            <div
              data-elementor-type="wp-page"
              data-elementor-id="1896"
              className="elementor elementor-1896"
            >
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-4f15d2d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="4f15d2d"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0f42938"
                    data-id="0f42938"
                    data-element_type="column"
                  >
                    {fullImage1 && (
                      <img
                        alt="pgdm colleges in up"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                        }}
                        decoding="async"
                        src={fullImage1}
                        className="image wp-image-929 attachment-medium size-medium "
                      />
                    )}
                  </div>
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-788cb47"
                    data-id="788cb47"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-1161e5c elementor-widget elementor-widget-heading"
                        data-id="1161e5c"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h4 className="elementor-heading-title elementor-size-default">
                            {BusinessSetting && BusinessSetting.data
                              ? BusinessSetting.data.site_name
                              : ""}
                          </h4>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-6472e20 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="6472e20"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-divider">
                            <span className="elementor-divider-separator"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-943debb elementor-widget elementor-widget-heading"
                        data-id="943debb"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h6 className="elementor-heading-title elementor-size-default">
                            {BusinessSetting && BusinessSetting.data
                              ? BusinessSetting.data.address
                              : ""}
                          </h6>
                        </div>
                      </div>

                      <div
                        className="elementor-element elementor-element-8a8d811 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                        data-id="8a8d811"
                        data-element_type="widget"
                        data-widget_type="icon-list.default"
                      >
                        <div className="elementor-widget-container">
                          <ul className="elementor-icon-list-items">
                            <li className="elementor-icon-list-item">
                              <span className="elementor-icon-list-icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-phone-alt"
                                ></i>{" "}
                              </span>
                              <span className="elementor-icon-list-text">
                                Tel:{" "}
                                {BusinessSetting && BusinessSetting.data
                                  ? BusinessSetting.data.phone_no1
                                  : ""}
                              </span>
                            </li>
                            <li className="elementor-icon-list-item">
                              <a href="#">
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="fas fa-globe-americas"
                                  ></i>{" "}
                                </span>
                                <span className="elementor-icon-list-text">
                                  Website: https://mbabinabi.com/
                                </span>
                              </a>
                            </li>
                            <li className="elementor-icon-list-item">
                              <a href="#">
                                <span className="elementor-icon-list-icon">
                                  <i
                                    aria-hidden="true"
                                    className="far fa-envelope"
                                  ></i>{" "}
                                </span>
                                <span className="elementor-icon-list-text">
                                  E-mail :{" "}
                                  {BusinessSetting && BusinessSetting.data
                                    ? BusinessSetting.data.email_id1
                                    : ""}
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-a9b01da elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="a9b01da"
                data-element_type="section"
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e5f9e4d"
                    data-id="e5f9e4d"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-9538b10 elementor-widget elementor-widget-heading"
                        data-id="9538b10"
                        data-element_type="widget"
                        data-widget_type="heading.default"
                      >
                        <div className="elementor-widget-container">
                          <h4 className="elementor-heading-title elementor-size-default">
                            CONTACT FORM
                          </h4>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-a17bb0c elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                        data-id="a17bb0c"
                        data-element_type="widget"
                        data-widget_type="divider.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-divider">
                            <span className="elementor-divider-separator"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-element elementor-element-b077c9a elementor-widget elementor-widget-shortcode"
                        data-id="b077c9a"
                        data-element_type="widget"
                        data-widget_type="shortcode.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-shortcode">
                            <div
                              className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework"
                              data-form-theme="gravity-theme"
                              data-form-index="0"
                              id="gform_wrapper_1"
                            >
                              <div
                                id="gf_1"
                                className="gform_anchor"
                                tabindex="-1"
                              ></div>
                              <form
                                method="post"
                                encType="multipart/form-data"
                                onSubmit={handleSubmit2}
                              >
                                <div className="gform-body gform_body">
                                  <div
                                    id="gform_fields_1"
                                    className="gform_fields top_label form_sublabel_below description_below"
                                  >
                                    <div
                                      id="field_1_1"
                                      className="gfield2 gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                      data-js-reload="field_1_1"
                                    >
                                      <label
                                        className="gfield_label gform-field-label"
                                        for="input_1_1"
                                      >
                                        Full Name
                                        <span className="gfield_required">
                                          <span className="gfield_required gfield_required_text">
                                            (Required)
                                          </span>
                                        </span>
                                      </label>
                                      <div className="ginput_container ginput_container_text">
                                        <input
                                          name="name"
                                          value={formData2.name}
                                          id="input_1_1"
                                          type="text"
                                          onChange={handleChange2}
                                          className="large form-control"
                                          placeholder="Full Name"
                                          aria-required="true"
                                          aria-invalid="false"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      id="field_1_2"
                                      className="gfield2 gfield--type-email gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                      data-js-reload="field_1_2"
                                    >
                                      <label
                                        className="gfield_label gform-field-label"
                                        for="input_1_2"
                                      >
                                        Email Address
                                        <span className="gfield_required">
                                          <span className="gfield_required gfield_required_text">
                                            (Required)
                                          </span>
                                        </span>
                                      </label>
                                      <div className="ginput_container ginput_container_email">
                                        <input
                                          name="email"
                                          value={formData2.email}
                                          id="input_1_2"
                                          type="email"
                                          onChange={handleChange2}
                                          className="large form-control"
                                          placeholder="Email Address"
                                          aria-required="true"
                                          aria-invalid="false"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      id="field_1_3"
                                      className="gfield2 gfield--type-phone gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                      data-js-reload="field_1_3"
                                    >
                                      <label
                                        className="gfield_label gform-field-label"
                                        for="input_1_3"
                                      >
                                        Phone No.
                                        <span className="gfield_required">
                                          <span className="gfield_required gfield_required_text">
                                            (Required)
                                          </span>
                                        </span>
                                      </label>
                                      <div className="ginput_container ginput_container_phone">
                                        <input
                                          name="phone"
                                          value={formData2.phone}
                                          id="input_1_3"
                                          type="tel"
                                          onChange={handleChange2}
                                          className="large form-control"
                                          placeholder="Phone No."
                                          aria-required="true"
                                          aria-invalid="false"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      id="field_1_4"
                                      className="gfield2 gfield--type-text gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                      data-js-reload="field_1_4"
                                    >
                                      <label
                                        className="gfield_label gform-field-label"
                                        for="input_1_4"
                                      >
                                        City
                                      </label>
                                      <div className="ginput_container ginput_container_text">
                                        <input
                                          name="city"
                                          value={formData2.city}
                                          onChange={handleChange2}
                                          id="input_1_4"
                                          type="text"
                                          className="large form-control"
                                          placeholder="city"
                                          aria-invalid="false"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      id="field_1_5"
                                      className="gfield2 gfield--type-textarea gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                      data-js-reload="field_1_5"
                                    >
                                      <label
                                        className="gfield_label gform-field-label"
                                        for="input_1_5"
                                      >
                                        Message
                                      </label>
                                      <div className="ginput_container ">
                                        <textarea
                                          name="message"
                                          value={formData2.message}
                                          onChange={handleChange2}
                                          id="input_1_5"
                                          className="textarea small form-control"
                                          placeholder="Message"
                                          aria-invalid="false"
                                          rows="10"
                                          cols="50"
                                        ></textarea>
                                      </div>
                                    </div>
                                    <div
                                      id="field_1_6"
                                      className="gfield gfield--type-captcha gfield--width-full field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible"
                                      data-js-reload="field_1_6"
                                    >
                                      <label
                                        className="gfield_label gform-field-label"
                                        for="input_1_6"
                                      >
                                        CAPTCHA
                                      </label>
                                      <div
                                        id="input_1_6"
                                        className="ginput_container ginput_recaptcha"
                                        data-sitekey="6Le33jYgAAAAAHEf7cxacf3DC9xqhEEqAIWisWwH"
                                        data-theme="light"
                                        data-tabindex="-1"
                                        data-size="invisible"
                                        data-badge="bottomright"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="gform_footer top_label">
                                  {" "}
                                  <input
                                    type="submit"
                                    id="gform_submit_button_1"
                                    className="gform_button button"
                                    value="Submit Details"
                                    onclick='if(window["gf_submitting_1"]){return false;}  window["gf_submitting_1"]=true;  '
                                    onkeypress='if( event.keyCode == 13 ){ if(window["gf_submitting_1"]){return false;} window["gf_submitting_1"]=true;  jQuery("#gform_1").trigger("submit",[true]); }'
                                  />
                                  <input
                                    type="hidden"
                                    name="gform_ajax"
                                    value="form_id=1&amp;title=&amp;description=&amp;tabindex=0"
                                  />
                                  <input
                                    type="hidden"
                                    className="gform_hidden"
                                    name="is_submit_1"
                                    value="1"
                                  />
                                  <input
                                    type="hidden"
                                    className="gform_hidden"
                                    name="gform_submit"
                                    value="1"
                                  />
                                  <input
                                    type="hidden"
                                    className="gform_hidden"
                                    name="gform_unique_id"
                                    value=""
                                  />
                                  <input
                                    type="hidden"
                                    className="gform_hidden"
                                    name="state_1"
                                    value="WyJbXSIsImE2Yjc3MDU1MjZhYTRkYTY4MmI0MzY0Mjk4NDJlNWVlIl0="
                                  />
                                  <input
                                    type="hidden"
                                    className="gform_hidden"
                                    name="gform_target_page_number_1"
                                    id="gform_target_page_number_1"
                                    value="0"
                                  />
                                  <input
                                    type="hidden"
                                    className="gform_hidden"
                                    name="gform_source_page_number_1"
                                    id="gform_source_page_number_1"
                                    value="1"
                                  />
                                  <input
                                    type="hidden"
                                    name="gform_field_values"
                                    value=""
                                  />
                                </div>
                              </form>
                            </div>
                            <iframe
                              data-lazyloaded="1"
                              src="about:blank"
                              style={{
                                display: "none",
                                width: "0px",
                                height: "0px",
                              }}
                              data-src="about:blank"
                              name="gform_ajax_frame_1"
                              id="gform_ajax_frame_1"
                              title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                            ></iframe>
                            <noscript>
                              <iframe
                                style={{
                                  display: "none",
                                  width: "0px",
                                  height: "0px",
                                }}
                                src="about:blank"
                                name="gform_ajax_frame_1"
                                id="gform_ajax_frame_1"
                                title="This iframe contains the logic required to handle Ajax powered Gravity Forms."
                              ></iframe>
                            </noscript>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-be2969e"
                    data-id="be2969e"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-e218971 elementor-widget elementor-widget-google_maps"
                        data-id="e218971"
                        data-element_type="widget"
                        data-widget_type="google_maps.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-custom-embed">
                            <iframe
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              title={
                                BusinessSetting && BusinessSetting.data
                                  ? BusinessSetting.data.site
                                  : ""
                              }
                              aria-label={
                                BusinessSetting && BusinessSetting.data
                                  ? BusinessSetting.data.site
                                  : ""
                              }
                              data-src={
                                BusinessSetting && BusinessSetting.data
                                  ? BusinessSetting.data.map
                                  : ""
                              }
                              className="lazyload"
                              src={
                                BusinessSetting && BusinessSetting.data
                                  ? BusinessSetting.data.map
                                  : ""
                              }
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer BusinessSetting={BusinessSetting} />
    </div>):null}</>
  );
};

export default ContactUs;
