

import { BASE_URL, ENDPOINTS, ImageBASE_URL } from "../ApiContext/Config";



import { useParams, useLocation } from "react-router-dom";

import React, { useEffect, useState, useContext } from "react";

import Footer from "../component/Footer";

import { ApiContext } from "../ApiContext/ApiContext";





import "../component/CustomCarousel.css";
import Header from "../component/Header";


const IndustrySpeakDetail = () => {
    const location = useLocation();
    const state =
        location.state || JSON.parse(localStorage.getItem("academyState"));
    const data = state ? state.data : null;


    const {
        BusinessSetting,
        Page,
        Home,
        loading,
        error2,
        fetchAllData,

    } = useContext(ApiContext);

    useEffect(() => {

        fetchAllData();

    }, []);





    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        qualification: "",
        city: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

        } catch (error) {

        }
    };

    const backgroundImageUrl =
        BusinessSetting && BusinessSetting.data
            ? ImageBASE_URL + BusinessSetting.data.image_baner
            : "";




    return (

        <>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundColor: '#f0f0f0',
                    }}
                >
                    <img
                        src="/assets/favicon.png"
                        alt="Loading"
                        style={{
                            width: '100px',
                            height: '100px',
                        }}
                    />
                </div>
            ) : error2 ? (
                <p>{error2}</p>
            ) : BusinessSetting ? (

                <div className="page-template page-template-page-full page-template-page-full-php page page-id-1041 user-registration-page mega-menu-main-menu elementor-default elementor-kit-5 elementor-page elementor-page-1041">
                    <div
                        data-elementor-type="header"
                        data-elementor-id="8"
                        className="elementor elementor-8 elementor-location-header"
                    >

                        {BusinessSetting ? <Header BusinessSetting={BusinessSetting} Page={Page} /> : null}
                    </div>

                    <div
                        data-elementor-type="section"
                        data-elementor-id="1208"
                        className="elementor elementor-1208"
                    >
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-68d8217d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="68d8217d"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                            style={{
                                backgroundImage: backgroundImageUrl
                                    ? `url(${backgroundImageUrl})`
                                    : "none",

                                backgroundPosition: "center",
                            }}
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6d3a34c6"
                                    data-id="6d3a34c6"
                                    data-element_type="column"
                                >
                                      Industry Speaks
                                    <div className="elementor-widget-wrap"></div>
                                </div>
                            </div>
                        </section>
                        <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-ff48016 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="ff48016"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                            style={{
                                backgroundImage: backgroundImageUrl
                                    ? `url(${backgroundImageUrl})`
                                    : "none",

                                backgroundPosition: "center",
                            }}
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-27671cf"
                                    data-id="27671cf"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-84eb36b elementor-icon-list--layout-inline elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                            data-id="84eb36b"
                                            data-element_type="widget"
                                            data-widget_type="icon-list.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <ul className="elementor-icon-list-items elementor-inline-items">
                                                    <li className="elementor-icon-list-item elementor-inline-item">
                                                        <a href="#">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-home"></i>{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text"></span>
                                                        </a>
                                                    </li>
                                                    <li className="elementor-icon-list-item elementor-inline-item">
                                                        <a href="#">
                                                            <span className="elementor-icon-list-text">
                                                                {data.name}
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-32ba1f2"
                                    data-id="32ba1f2"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap"></div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div
                        id="post-1041"
                        className="content post-1041 page type-page status-publish has-post-thumbnail hentry"
                    >
                        <div className="row inner-page-fullwidth">

                            {/* <!-- instructor-details-area --> */}

                            <section className="instructor-details-area my-4">
                                <div className="container">
                                    <div className="row w-100">
                                        <div className="col-12">
                                            <div className="instructor-details-wrap shadow-lg p-3 mb-5 bg-white rounded">
                                                <div className="row align-items-start">

                                                    {/* Left Side: Image */}
                                                    <div className="col-md-4 col-sm-12">
                                                        <div className="instructor-details-img text-center text-md-left">
                                                            <img
                                                                src={`${ImageBASE_URL + data.image}`}
                                                                alt={data.name}
                                                                className="img-fluid rounded"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Right Side: Content */}
                                                    <div className="col-md-8 col-sm-12">
                                                        <div className="instructor-details-content">
                                                            <div className="content-top">
                                                                <div className="left-side-content">
                                                                    <h2 className="title">{data.name}</h2>
                                                                    <span>{data.designation}</span>
                                                                </div>
                                                            </div>



                                                            <div className="bio-content">
                                                                <h4 className="title">Short Bio:</h4>
                                                                <p dangerouslySetInnerHTML={{ __html: `${data.description}` }}></p>
                                                            </div>

                                                         
                                                           
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>



                            {/* <!-- instructor-details-area-end --> */}



                        </div>
                    </div>

                    <Footer BusinessSetting={BusinessSetting} />
                </div>) : null}</>
    );
};

export default IndustrySpeakDetail;



